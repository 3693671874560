import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DateTime, IANAZone } from 'luxon';
import { SortEvent } from 'primeng/api';
import { OverlayPanel } from 'primeng/overlaypanel';
import { Table } from 'primeng/table';
import { OrderInfo } from 'src/app/models/order-info';
import { CurrentOrdersService } from 'src/app/services/current-orders.service';

interface OrderRecords extends OrderInfo {
    poNumsJoined: string;
    schedPickupString: string;
    actualPickupString: string;
    schedDeliveryString: string;
    actualDeliveryString: string;
    carriersString: string;
}

@Component({
  selector: 'app-current-orders',
  templateUrl: './current-orders.component.html',
  styleUrls: ['./current-orders.component.scss']
})
export class CurrentOrdersComponent implements OnInit, OnDestroy {

    loadingOrders: boolean = true;
    orders: OrderRecords[] = [];

    overlayStrings: string[] = [];

    @ViewChild('orderstable', { static: false }) table: Table | null = null;
    @ViewChild('listOverlay', { static: false }) listOverlay: OverlayPanel | null = null;

    constructor(private ordersService: CurrentOrdersService) {}

    ngOnInit(): void {
        this.loadingOrders = true;
        this.ordersService.GetCurrentOrders().subscribe(orders => {
            this.loadingOrders = false;
            this.orders = orders.map(o => ({
                ...o,
                poNumsJoined: o.purchaseOrderNumbers.join('|'),
                carriersString: o.carriers.map(c => c.name).join('|'),
                schedPickupString: this.getScheduledPickupWindow(o),
                schedDeliveryString: this.getScheduledDeliveryWindow(o),
                actualPickupString: this.getActualPickupWindow(o),
                actualDeliveryString: this.getActualDeliveryWindow(o)
            }));
        });
    }

    ngOnDestroy(): void {
    }

    private windowToString(
        timezone: string,
        start: DateTime | undefined,
        end: DateTime | undefined,
        startHasTime: boolean,
        endHasTime: boolean
    ): string {
        const zone = IANAZone.create(timezone);

        if (zone.isValid) {
            start = start?.setZone(zone);
            end = end?.setZone(zone);
        }

        if (start && end)
            return start.toFormat(startHasTime ? 'M/d/yy HH:mm' : 'M/d/yy')
                + ' - '
                + end.toFormat(endHasTime ? 'M/d/yy HH:mm ZZZZ' : 'M/d/yy');
        else if (start)
            return start.toFormat(startHasTime ? 'M/d/yy HH:mm ZZZZ' : 'M/d/yyyy');
        else if (end)
            return end.toFormat(endHasTime ? 'M/d/yy HH:mm ZZZZ' : 'M/d/yyyy');
        else
            return '';
    }

    private getScheduledPickupWindow(order: OrderInfo): string {
        return this.windowToString(
            order.pickup.timezone,
            order.pickupAppointmentStart?.date,
            order.pickupAppointmentEnd?.date,
            order.pickupAppointmentStart?.hasTime ?? true,
            order.pickupAppointmentEnd?.hasTime ?? true);
    }

    private getActualPickupWindow(order: OrderInfo): string {
        return this.windowToString(
            order.pickup.timezone,
            order.pickupArrived?.date,
            order.pickupDeparted?.date,
            order.pickupArrived?.hasTime ?? true,
            order.pickupDeparted?.hasTime ?? true);
    }

    private getScheduledDeliveryWindow(order: OrderInfo): string {
        return this.windowToString(
            order.delivery.timezone,
            order.deliveryAppointmentStart?.date,
            order.deliveryAppointmentEnd?.date,
            order.deliveryAppointmentStart?.hasTime ?? true,
            order.deliveryAppointmentEnd?.hasTime ?? true);
    }

    private getActualDeliveryWindow(order: OrderInfo): string {
        return this.windowToString(
            order.delivery.timezone,
            order.deliveryArrived?.date,
            order.deliveryDeparted?.date,
            order.deliveryArrived?.hasTime ?? true,
            order.deliveryDeparted?.hasTime ?? true);
    }

    showPoOverlay(event: Event, row: OrderRecords): void {
        if (row.purchaseOrderNumbers.length <= 1)
            return;

        this.overlayStrings = row.purchaseOrderNumbers;
        this.listOverlay?.toggle(event);
    }

    showCarriersOverlay(event: Event, row: OrderRecords): void {
        if (row.purchaseOrderNumbers.length <= 1)
            return;

        this.overlayStrings = row.carriers.map(c => c.name);
        this.listOverlay?.toggle(event);
    }

    onSearch(event: Event): void {
        if (this.table && event.target)
            this.table.filterGlobal((<HTMLInputElement> event.target).value, 'contains');
    }

    customSort(event: SortEvent): void {
        let getter: (o: OrderInfo) => string;


        const sortByDates = (
            start: (o: OrderInfo) => DateTime | undefined | null,
            end: (o: OrderInfo) => DateTime | undefined | null): void => {

            const g = (o: OrderInfo): DateTime => start(o)
                ?? end(o)
                ?? DateTime.fromObject({ year: 2000, month: 1, day: 1 });
            event.data?.sort((l, r) => g(l).diff(g(r)).as('milliseconds') * (event.order ?? 1));
        };

        switch (event.field) {
            case 'schedpickup':
                sortByDates(o => o.pickupAppointmentStart?.date, o => o.pickupAppointmentEnd?.date);
                return;
            case 'scheddelivery':
                sortByDates(o => o.deliveryAppointmentStart?.date, o => o.deliveryAppointmentEnd?.date);
                return;
            case 'actualpickup':
                sortByDates(o => o.pickupArrived?.date, o => o.pickupDeparted?.date);
                return;
            case 'actualdelivery':
                sortByDates(o => o.deliveryArrived?.date, o => o.deliveryDeparted?.date);
                return;
            case 'po':
                getter = o => o.purchaseOrderNumbers.length ? o.purchaseOrderNumbers[0] : '';
                break;
            case 'pickup':
                getter = o => o.pickup?.name ?? '';
                break;
            case 'delivery':
                getter = o => o.delivery?.name ?? '';
                break;
            case 'carrier':
                getter = o => o.carriers.length ? o.carriers[0].name : '';
                break;
            case 'customer':
                getter = o => o.customer?.name ?? '';
                break;
            case 'supplier':
                getter = o => o.supplier?.name ?? '';
                break;
            case 'customId':
                getter = o => o.customId;
                break;
            case 'status':
                getter = o => o.status;
                break;
            default:
                throw new Error('unknown field');
        }

        event.data?.sort((l, r) => getter(l).localeCompare(getter(r)) * (event.order ?? 1));
    }
}
