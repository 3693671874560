import { Environment } from './environment.interface';

const apiUrl = 'https://propak-supplierlink-api-test.azurewebsites.net/';

export const environment: Environment = {
    production: false,
    buildNumber: '20240130.2',

    authSettings: {
        domain: 'propak.auth0.com',
        clientId: 'y1jn5eBV03X39CN28PfKjZTB3w5oifDW',
        authorizationParams: {
            redirect_uri: 'https://supplierlink-test.propak.com/',
            audience: 'SupplierLinkApi'
        },
        httpInterceptor: {
            allowedList: [
                {
                    uri: apiUrl + '*',
                    tokenOptions: {
                        authorizationParams: {
                            audience: 'SupplierLinkApi'
                        }
                    }
                }
            ]
        }
    },

    applicationInsightsKey: '5a6e3b86-1e99-4a16-861f-a0d4a9153898',

    apiBaseUrl: apiUrl,

    features: {
    }
};
