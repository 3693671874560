<div class="layout-wrapper layout-menu-static layout-menu-light" [ngClass]="{'layout-menu-overlay-active': overlayMenuActive,
                                        'layout-menu-mobile-active': staticMenuMobileActive,
                                        'layout-menu-static-inactive': staticMenuDesktopInactive}"
  (click)="onLayoutClick()">

  <app-theme-topbar></app-theme-topbar>

  <app-theme-menu [reset]="resetMenu"></app-theme-menu>

  <div class="layout-main">
    <div class="loading-overlay" [class.shown]="showLoadingOverlay">
      <i class="pi pi-spin pi-spinner" *ngIf="!authMessage"></i>
    </div>
    <app-theme-breadcrumb></app-theme-breadcrumb>

    <ng-content></ng-content>

    <div class="layout-content">
      <router-outlet></router-outlet>
    </div>

    <app-theme-footer [buildNumber]="buildNumber"></app-theme-footer>
  </div>

  <p-toast position="top-right">
    <ng-template let-message pTemplate="message">
      <h3>{{message.summary}}</h3>
      <p>{{message.detail}}</p>
      <ul *ngIf="message.data">
        <li *ngFor="let error of message.data">{{error}}</li>
      </ul>
    </ng-template>
  </p-toast>

  <div class="p-toast p-component p-toast-center-center" *ngIf="authMessage"
    style="top: 50%; left: 50%; margin: -10rem 0 0 -6rem; z-index: 2000">
    <div class="p-toast-message p-toast-message-info">
      <div class="p-toast-message-content">
        <h3>{{authMessage.summary}}</h3>
        <p>{{authMessage.detail}}</p>
        <div style="text-align: center;">
          <i class="pi pi-spinner pi-spin"></i>
        </div>
      </div>
    </div>
  </div>
</div>
