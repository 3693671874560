import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

import { AuthService } from '../services/auth.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {
    constructor(private authService: AuthService) {
    }

    canActivate(next: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<boolean> {
        if (!next.data['roles'] || !next.data['roles'].length)
            return this.authService.isAuthenticated();
        else
            return this.authService.isUserInAnyRoles(next.data['roles']).pipe(
                tap(matches => {
                    if (!matches)
                        this.redirect();
                }));
    }

    canActivateChild(childRoute: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<boolean> {
        if (!childRoute.data['roles'] || !childRoute.data['roles'].length)
            return this.authService.isAuthenticated();
        else
            return this.authService.isUserInAnyRoles(childRoute.data['roles']).pipe(
                tap(matches => {
                    if (!matches)
                        this.redirect();
                }));
    }

    public redirect(): void {
        location.replace('/#/unauthorized');
    }
}
