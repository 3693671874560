<h1>Load Request</h1>

<div class="p-grid">
  <div class="p-col-12 p-xl-8">
    <div class="filters" [formGroup]="loadRequestForm">
      <p-card header="Order Info">
        <div class="p-grid">
          <div class="p-col-6 form-group">
            <label for="supplierSelect">Supplier Group</label>
            <p-dropdown
              id="supplierSelect"
              placeholder="-- Select Supplier Group --"
              [options]="supplierOptions"
              [filter]="true"
              formControlName="supplierId"
              inputId="supplierSelect"
              [editable]="false"
              appendTo="body">
            </p-dropdown>
            <error-block
              [field]="supplierId"
              [messages]="{
                required: 'Supplier Group is required.'
              }">
            </error-block>
          </div>
        </div>
        <div class="p-grid">
          <div class="p-col-6 form-group">
            <label for="accountSelect">Supplier</label>
            <p-dropdown
              id="accountSelect"
              placeholder="-- Select Supplier --"
              [options]="accountOptions"
              [filter]="true"
              formControlName="accountId"
              inputId="accountSelect"
              [editable]="false"
              appendTo="body">
            </p-dropdown>
            <error-block
              [field]="accountId"
              [messages]="{
                required: 'Supplier is required.'
              }">
            </error-block>
          </div>
          <div class="p-col-6 form-group">
            <label for="customerSelect">Customer</label>
            <p-dropdown
              id="customerSelect"
              [options]="customerOptions"
              [filter]="true"
              formControlName="customerId"
              inputId="customerSelect"
              [editable]="false"
              appendTo="body">
            </p-dropdown>
            <error-block
              [field]="customerId"
              [messages]="{
                  required: 'Customer is required.'
                  }"></error-block>
          </div>
        </div>
      </p-card>
      <p-card header="Shipment Times">
    <!--     TODO: Split date times, clear fields, and typable-->
        <div class="p-grid">
          <div class="p-col-6 p-md-3 form-group">
            <label>Pickup Start Date</label>
            <p-calendar
              formControlName="pickupStartDate">
            </p-calendar>
            <error-block
              [field]="pickupStartDate"
              [messages]="{
                required: 'Pickup Start Date is required.'
              }">
            </error-block>
          </div>
          <div class="p-col-6 p-md-3 form-group">
            <label>Pickup Start Time</label>
            <p-calendar
              [timeOnly]="true"
              formControlName="pickupStartTime"
              [showTime]="true"
              hourFormat="12"
              [stepMinute]="15">
            </p-calendar>
            <error-block
              [field]="pickupStartTime"
              [messages]="{
              required: 'Pickup Start Time is required.'
              }">
            </error-block>
          </div>
          <div class="p-col-6 p-md-3 form-group">
            <label>Pickup End Date</label>
            <p-calendar
              formControlName="pickupEndDate">
            </p-calendar>
            <error-block
              [field]="pickupEndDate"
              [messages]="{
              required: 'Pickup End Date is required.'
              }">
            </error-block>
            <error-block
              [field]="loadRequestForm"
              [messages]="{
                pickupWindowSequence: 'Pickup End must be after Pickup Start.'
              }">
            </error-block>
          </div>
          <div class="p-col-6 p-md-3 form-group">
            <label>Pickup End Time</label>
            <p-calendar
              [timeOnly]="true"
              formControlName="pickupEndTime"
              [showTime]="true"
              hourFormat="12"
              [stepMinute]="15">
            </p-calendar>
            <error-block
              [field]="pickupEndTime"
              [messages]="{
                required: 'Pickup End Time is required.'
              }"></error-block>
          </div>
        </div>
        <div class="p-grid">
          <div class="p-col-6 p-md-3 form-group">
            <label>Delivery Start Date</label>
            <p-calendar
              formControlName="deliveryStartDate">
            </p-calendar>
            <error-block
              [field]="deliveryStartDate"
              [messages]="{
              required: 'Delivery Start Date is required.'
              }">
            </error-block>
            <error-block
              [field]="loadRequestForm"
              [messages]="{
                startWindowSequence: 'Delivery Start must be after Pickup Start.'
              }">
            </error-block>
          </div>
          <div class="p-col-6 p-md-3 form-group">
            <label>Delivery Start Time</label>
            <p-calendar
              [timeOnly]="true"
              formControlName="deliveryStartTime"
              [showTime]="true"
              hourFormat="12"
              [stepMinute]="15">
            </p-calendar>
            <error-block
              [field]="deliveryStartTime"
              [messages]="{
              required: 'Delivery Start Time is required.'
              }"></error-block>
          </div>
          <div class="p-col-6 p-md-3 form-group">
            <label>Delivery End Date</label>
            <p-calendar
              formControlName="deliveryEndDate">
            </p-calendar>
            <error-block
              [field]="deliveryEndDate"
              [messages]="{
                required: 'Delivery End Date is required.'
              }">
            </error-block>
            <error-block
              [field]="loadRequestForm"
              [messages]="{
                deliveryWindowSequence: 'Delivery End must be after Delivery Start.',
                endWindowSequence: 'Delivery End must be after Pickup End'
              }">
            </error-block>
          </div>
          <div class="p-col-6 p-md-3 form-group">
            <label>Delivery End Time</label>
            <p-calendar
              [timeOnly]="true"
              formControlName="deliveryEndTime"
              [showTime]="true"
              hourFormat="12"
              [stepMinute]="15">
            </p-calendar>
            <error-block
              [field]="deliveryEndTime"
              [messages]="{
                required: 'Delivery End Time is required.'
              }">
            </error-block>
          </div>
        </div>
      </p-card>
      <p-card header="Shipment Info">
        <div class="p-grid">
          <div class="p-col-6 form-group">
            <label for="shipFromSiteSelect">Ship From</label>
            <p-dropdown
              id="shipFromSiteSelect"
              placeholder="-- Select Ship From --"
              [options]="shipFroms"
              [filter]="true"
              formControlName="shipFromSiteId"
              inputId="shipFromSiteSelect"
              [editable]="false">
            </p-dropdown>
            <error-block
              [field]="shipFromSiteId"
              [messages]="{
                required: 'Ship From is required.'
                }">
            </error-block>
            <div class="inset" *ngIf="selectedShipFrom?.address">
              {{ selectedShipFrom?.address?.line1 }} <br *ngIf="selectedShipFrom?.address?.line1">
              {{ selectedShipFrom?.address?.line2 }} <br *ngIf="selectedShipFrom?.address?.line2">
              {{ selectedShipFrom?.address?.city }},
              {{ selectedShipFrom?.address?.state }},
              {{ selectedShipFrom?.address?.country }}
              {{ selectedShipFrom?.address?.zip }}
            </div>
          </div>
          <div class="p-col-6 form-group">
            <label for="shipToSiteSelect">Ship To</label>
            <p-dropdown
              id="shipToSiteSelect"
              placeholder="-- Select Ship To --"
              [options]="shipTos"
              [filter]="true"
              formControlName="shipToSiteId"
              inputId="shipToSiteSelect"
              [editable]="false"
              appendTo="body">
            </p-dropdown>
            <error-block
              [field]="shipToSiteId"
              [messages]="{
                required: 'Ship To is required.'
                }">
            </error-block>
            <div class="inset" *ngIf="selectedShipTo?.address">
              {{ selectedShipTo?.address?.line1 }} <br *ngIf="selectedShipTo?.address?.line1">
              {{ selectedShipTo?.address?.line2 }} <br *ngIf="selectedShipTo?.address?.line2">
              {{ selectedShipTo?.address?.city }},
              {{ selectedShipTo?.address?.state }},
              {{ selectedShipTo?.address?.country }}
              {{ selectedShipTo?.address?.zip }}
            </div>
          </div>
        </div>
        <div class="p-grid">
          <div class="p-col-6 form-group">
            <label for="pickup-notes">Pickup Notes</label>
            <textarea
              pInputTextarea
              id="pickup-notes"
              formControlName="pickupNote"
              rows="5"
              cols="50">
            </textarea>
          </div>
          <div class="p-col-6 form-group">
            <label for="delivery-notes">Delivery Notes</label>
            <textarea pInputTextarea
              id="delivery-notes"
              formControlName="deliveryNote"
              rows="5"
              cols="50">
            </textarea>
          </div>
        </div>
        <div class="p-grid" formArrayName="purchaseOrders">
          <div class="p-col-6 p-md-4 p-lg-3" *ngFor="let _ of purchaseOrders.controls; let index = index">
            <div class="p-inputgroup" >
              <label for="index" class="p-inputgroup-addon">PO#</label>
              <input pInputText
                [formControlName]="index"
                id="index"/>
              <button pButton type="button"
                      *ngIf="purchaseOrders.length > 1"
                      class="p-button-danger p-d-flex"
                      icon="pi pi-minus"
                      (click)="removePurchaseOrderNumber(index)">
              </button>
            </div>
          </div>
        </div>
        <div>
          <button pButton
                  [disabled]="purchaseOrders.controls.length >= 20"
                  (click)="addPurchaseOrderNumber()"
                  icon="pi pi-plus"
                  label="Add PO#">
          </button>
        </div>
      </p-card>
      <p-card header="Item Info">
        <div class="p-grid">
          <div class="p-col-6 p-lg-4" *ngFor="let item of orderItems; let index = index">
            <p-card>
              <ng-template pTemplate="header">
                <div class="p-card-title">{{item.description}}</div>

                <button pButton type="button"
                  *ngIf="orderItems.length > 1"
                  class="p-button-danger"
                  icon="pi pi-minus"
                  (click)="removeItem(item)">
                </button>
              </ng-template>
              <table>
                <tr>
                  <td class="key">Handling Qty</td>
                  <td>{{item.handlingQuantity}} {{lookupHandlingUnit(item.handlingUnitType) | async}}</td>
                </tr>
                <tr>
                  <td class="key">Dimensions</td>
                  <td>{{item.length}}x{{item.width}}x{{item.height}} {{ lookupDimUnit(item.dimensionUnitType) | async }}</td>
                </tr>
                <tr>
                  <td class="key">Gross Weight</td>
                  <td>{{item.totalGrossWeight}}</td>
                </tr>
              </table>
              <div class="icons">
                <img *ngIf="item.minTemp || item.maxTemp" src="assets/temperature-low-solid.svg" alt="temperature-low-solid"/>
                <i *ngIf="item.isHazmat" class="pi pi-exclamation-triangle"></i>
              </div>
            </p-card>
          </div>
        </div>
        <div>
          <button pButton
                  [disabled]="!supplierId.value || orderItems.length >= 20"
                  (click)="addItemDialog()"
                  icon="pi pi-plus"
                  label="Add Item">
          </button>
        </div>
      </p-card>
    </div>

    <p-card>
      <div class="form-buttons">
        <button pButton
          type="button"
          label="Submit"
          class="p-ac-center"
          icon="pi pi-plus"
          [disabled]="!loadRequestForm.valid || orderItems.length === 0 || !purchaseOrders.valid"
          (click)="submitOrder()">

        </button>
      </div>
    </p-card>
  </div>
</div>

<p-dialog header="Add/Create Item" [(visible)]="showItemDialog" [modal]="true" [closable]="false" appendTo="body">
  <app-item-form
    [supplierId]="supplierId.value"
    [items]="supplierItems"
    (itemEvent)="itemAdded($event)">
  </app-item-form>
</p-dialog>
