import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Item } from '../../../models/item';
import { Message, MessageService, SelectItem } from 'primeng/api';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ItemService } from '../../../services/item.service';
import { LookupService } from '../../../services/lookup.service';
import { Lookup } from '../../../models/lookup';
import { BehaviorSubject, concat, map, Observable, of, Subscription } from 'rxjs';
import { streamControlValue } from 'src/app/helpers/custom-operators';

@Component({
  selector: 'app-item-form',
  templateUrl: './item-form.component.html',
  styleUrls: ['./item-form.component.scss']
})
export class ItemFormComponent implements OnInit, OnDestroy {
    isItemSelected: boolean = false;
    unitSelect: SelectItem[] = [];
    handlingUnitSelect: SelectItem[] = [{ label: 'Pallets', value: 0 }];
    dimensionUnitSelect: SelectItem[] = [{ label: 'Inch', value: 0 }];
    weightUnitSelect: SelectItem[] = [{ label: 'Lbs', value: 0 }];
    tempUnitSelect: SelectItem[] = [{ label: 'Celsius', value: 0 }];
    freightClassSelect: SelectItem[] = [{ label: 'Dry Van', value: 0 }];
    packingGroupSelect: SelectItem[] = [{ label: '', value: 0 }];
    saving: boolean = false;
    lookups: Lookup[] | null = null;

    itemForm: FormGroup = new FormGroup({
        itemId: new FormControl(null),
        description: new FormControl('', [Validators.required, Validators.maxLength(100)]),
        quantity: new FormControl('', Validators.required),
        unitType: new FormControl('', Validators.required),
        handlingQuantity: new FormControl('', Validators.required),
        handlingUnitType: new FormControl(null, Validators.required),
        itemNumber: new FormControl(''),
        length: new FormControl(null, Validators.required),
        width: new FormControl(''),
        height: new FormControl('', Validators.required),
        dimensionUnitType: new FormControl(null, Validators.required),
        totalGrossWeight: new FormControl('', Validators.required),
        weightUnitType: new FormControl(null, Validators.required),
        isTempControlled: new FormControl(false),
        minTemp: new FormControl(null),
        maxTemp: new FormControl(null, [
            control => +control.value < +(this.itemForm?.controls['minTemp'].value ?? 0)
                ? { tempOrder: true }
                : null
        ]),
        temperatureUnitType: new FormControl(null),
        nmfcNumber: new FormControl(null, [ Validators.minLength(4), Validators.maxLength(6), Validators.pattern(/^\d+$/) ]),
        nmfcSub: new FormControl(null, [ Validators.maxLength(2), Validators.pattern(/^\d+$/) ]),
        freightClassType: new FormControl(null),
        loadValue: new FormControl(null),
        isHazmat: new FormControl(false),
        identificationNumber: new FormControl(null, [ Validators.maxLength(4), Validators.pattern(/^\d+$/) ]),
        properShippingName: new FormControl(null),
        hazardClass: new FormControl(null, [ Validators.maxLength(3), Validators.pattern(/^\d(\.\d)?$/) ]),
        packingGroup: new FormControl(null),
        emergencyContact: new FormControl(null),

    });

    get itemId(): FormControl { return <FormControl> this.itemForm.get('itemId'); }
    get description(): FormControl { return <FormControl> this.itemForm.get('description'); }
    get quantity(): FormControl { return <FormControl> this.itemForm.get('quantity'); }
    get unitType(): FormControl { return <FormControl> this.itemForm.get('unitType'); }
    get handlingQuantity(): FormControl { return <FormControl> this.itemForm.get('handlingQuantity'); }
    get handlingUnitType(): FormControl { return <FormControl> this.itemForm.get('handlingUnitType'); }
    get itemNumber(): FormControl { return <FormControl> this.itemForm.get('itemNumber'); }
    get length(): FormControl { return <FormControl> this.itemForm.get('length'); }
    get width(): FormControl { return <FormControl> this.itemForm.get('width'); }
    get height(): FormControl { return <FormControl> this.itemForm.get('height'); }
    get dimensionUnitType(): FormControl { return <FormControl> this.itemForm.get('dimensionUnitType'); }
    get totalGrossWeight(): FormControl { return <FormControl> this.itemForm.get('totalGrossWeight'); }
    get weightUnitType(): FormControl { return <FormControl> this.itemForm.get('weightUnitType'); }
    get minTemp(): FormControl { return <FormControl> this.itemForm.get('minTemp'); }
    get maxTemp(): FormControl { return <FormControl> this.itemForm.get('maxTemp'); }
    get temperatureUnitType(): FormControl { return <FormControl> this.itemForm.get('temperatureUnitType'); }
    get nmfcNumber(): FormControl { return <FormControl> this.itemForm.get('nmfcNumber'); }
    get nmfcSub(): FormControl { return <FormControl> this.itemForm.get('nmfcSub'); }
    get freightClassType(): FormControl { return <FormControl> this.itemForm.get('freightClassType'); }
    get loadValue(): FormControl { return <FormControl> this.itemForm.get('loadValue'); }
    get isHazmat(): FormControl { return <FormControl> this.itemForm.get('isHazmat'); }
    get identificationNumber(): FormControl { return <FormControl> this.itemForm.get('identificationNumber'); }
    get properShippingName(): FormControl { return <FormControl> this.itemForm.get('properShippingName'); }
    get hazardClass(): FormControl { return <FormControl> this.itemForm.get('hazardClass'); }
    get packingGroup(): FormControl { return <FormControl> this.itemForm.get('packingGroup'); }
    get emergencyContact(): FormControl { return <FormControl> this.itemForm.get('emergencyContact'); }
    get isTempControlled(): FormControl { return <FormControl> this.itemForm.get('isTempControlled'); }


    get isTempControlledObs(): Observable<boolean> {
        return streamControlValue(this.isTempControlled);
    }

    get isHazmatObs(): Observable<boolean> {
        return streamControlValue(this.isHazmat);
    }

    private _supplierId: number | null = null;
    @Input() set supplierId(val: number | null) {
        if (val) {
            this._supplierId = val;
        }
    };
    get supplierId(): number | null {
        return this._supplierId;
    }

    private _items: BehaviorSubject<Item[]> = new BehaviorSubject<Item[]>([]);
    @Input() set items(val: Item[]) {
        if (val) {
            this._items.next(val);
        }
    }

    get items(): Item[] {
        return this._items.value;
    }

    get itemSelect(): Observable<SelectItem[]> {
        return this._items.pipe(map(items => [{value: 0, label: 'Create Item'},...items.map(i => <SelectItem>({ value: i.id, label: i.description }))]));
    };

    @Output() itemEvent: EventEmitter<Item> = new EventEmitter<Item>();
    hazmatLoad: boolean = false;
    readonly _itemSavedMessage: Message = {
        severity: 'success',
        summary: 'Item saved successfully',
        detail: ''
    };

    private subscriptions: Subscription[] = [];

    constructor(private lookupService: LookupService, private messageService: MessageService, private itemService: ItemService) {
    }

    ngOnInit(): void {
        this.subscriptions = [
            this.isHazmatObs.subscribe(isHazmat => {
                if (isHazmat) {
                    this.identificationNumber.addValidators(Validators.required);
                    this.properShippingName.addValidators(Validators.required);
                    this.hazardClass.addValidators(Validators.required);
                    this.packingGroup.addValidators(Validators.required);
                    this.emergencyContact.addValidators(Validators.required);
                }
                else {
                    this.identificationNumber.removeValidators(Validators.required);
                    this.properShippingName.removeValidators(Validators.required);
                    this.hazardClass.removeValidators(Validators.required);
                    this.packingGroup.removeValidators(Validators.required);
                    this.emergencyContact.removeValidators(Validators.required);
                }

                this.identificationNumber.updateValueAndValidity();
                this.properShippingName.updateValueAndValidity();
                this.hazardClass.updateValueAndValidity();
                this.packingGroup.updateValueAndValidity();
                this.emergencyContact.updateValueAndValidity();
            }),

            streamControlValue(this.isTempControlled).subscribe(isTempCtrl => {
                if (isTempCtrl) {
                    this.minTemp.addValidators(Validators.required);
                    this.maxTemp.addValidators(Validators.required);
                    this.temperatureUnitType.addValidators(Validators.required);
                }
                else {
                    this.minTemp.removeValidators(Validators.required);
                    this.maxTemp.removeValidators(Validators.required);
                    this.temperatureUnitType.removeValidators(Validators.required);
                }

                this.minTemp.updateValueAndValidity();
                this.maxTemp.updateValueAndValidity();
                this.temperatureUnitType.updateValueAndValidity();
        }),

            this.lookupService.Lookups.subscribe(l => {
                this.lookups = l;

                this.unitSelect = l
                    .find(x => x.id === 'Unit')
                    ?.values
                    .map(lookup => ({ value: lookup.key, label: lookup.value })) ?? [];
                this.handlingUnitSelect = l
                    .find(x => x.id === 'Unit')
                    ?.values
                    .map(lookup => ({ value: lookup.key, label: lookup.value })) ?? [];
                this.dimensionUnitSelect = l
                    .find(x => x.id === 'LengthUnits')
                    ?.values
                    .map(lookup => ({ value: lookup.key, label: lookup.value })) ?? [];
                this.weightUnitSelect = l
                    .find(x => x.id === 'WeightUnits')
                    ?.values
                    .map(lookup => ({ value: lookup.key, label: lookup.value })) ?? [];
                this.tempUnitSelect = l
                    .find(x => x.id === 'TemperatureUnits')
                    ?.values
                    .map(lookup => ({ value: lookup.key, label: lookup.value })) ?? [];
                this.freightClassSelect = l
                    .find(x => x.id === 'FreightClass')
                    ?.values
                    .map(lookup => ({ value: lookup.key, label: lookup.value })) ?? [];
                this.packingGroupSelect = l
                    .find(x => x.id === 'PackingGroup')
                    ?.values
                    .map(lookup => ({ value: lookup.key, label: lookup.value })) ?? [];
            }),

            streamControlValue(this.itemId).subscribe(v => {
                this.isItemSelected = v !== null;
            })
        ];
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(s => s.unsubscribe());
    }
    setItemFormInfo(e: any): void {
        if (e.value !== 0) {
            this.setItemInfo(e.value);
        }
    }

    setItemInfo(itemId: number): void {
        const item = this.items.find(i => i.id === itemId);
        if (item) {
            const values: { [field: string]: any } = {
                itemId: item.id,
                description: item.description,
                quantity: item.quantity,
                unitType: item.unitType,
                handlingQuantity: item.handlingQuantity,
                handlingUnitType: item.handlingUnitType,
                itemNumber: item.itemNumber,
                length: item.length,
                width: item.width,
                height: item.height,
                dimensionUnitType: item.dimensionUnitType,
                totalGrossWeight: item.totalGrossWeight,
                weightUnitType: item.weightUnitType,
                isTempControlled: !!item.minTemp || !!item.maxTemp || !!item.temperatureUnitType,
                minTemp: item.minTemp,
                maxTemp: item.maxTemp,
                temperatureUnitType: item.temperatureUnitType,
                nmfcNumber: item.nmfcNumber,
                nmfcSub: item.nmfcSub,
                freightClassType: item.freightClassType,
                loadValue: item.loadValue,
                isHazmat: !!item.isHazmat,
                identificationNumber: item.identificationNumber,
                properShippingName: item.properShippingName,
                hazardClass: item.hazardClass,
                packingGroup: item.packingGroup,
                emergencyContact: item.emergencyContact,
            };
            this.itemForm.setValue(values);
        }
    }

    closeDialog(): void {
        this.itemEvent.emit(undefined);
        this.itemForm.reset({isHazmat: false});
        this.isItemSelected = false;
    }

    saveItem(): void {
        if (!this.itemForm.valid) {
            this.itemForm.markAllAsTouched();
            this.messageService.add({ severity: 'warn', summary: 'Could not Create Item', detail: 'Please correct the listed errors.' });
            return;
        }

        this.saving = true;

        const formData = this.setItemModelData();

        if (this.itemId.value === 0)
            this.itemService.createItem(formData).subscribe(item => {
                this.itemEvent.emit(item);
                this.itemForm.reset();
                this.isItemSelected = false;
                this.messageService.add(this._itemSavedMessage);
                this.saving = false;
            }, _ => this.saving = false);
        else
            this.itemService.updateItem(formData).subscribe(item => {
                this.itemEvent.emit(item);
                this.itemForm.reset();
                this.isItemSelected = false;
                this.messageService.add(this._itemSavedMessage);
                this.saving = false;
            }, _ => this.saving = false);
    }

    setItemModelData(): Item {
        return {
            id: this.itemId.value,
            supplierId: this.supplierId ?? 0,
            description: this.description.value,
            quantity: this.quantity.value,
            length: this.length.value,
            width: this.width.value,
            height: this.height.value,
            unitType: this.unitType.value,
            handlingQuantity: this.handlingQuantity.value,
            handlingUnitType: this.handlingUnitType.value,
            itemNumber: this.itemNumber.value,
            dimensionUnitType: this.dimensionUnitType.value,
            totalGrossWeight: this.totalGrossWeight.value,
            weightUnitType: this.weightUnitType.value,
            minTemp: this.isTempControlled.value ? this.minTemp.value : null,
            maxTemp: this.isTempControlled.value ? this.maxTemp.value : null,
            temperatureUnitType: this.isTempControlled.value ? this.temperatureUnitType.value : null,
            nmfcNumber: this.nmfcNumber.value,
            nmfcSub: this.nmfcSub.value,
            freightClassType: this.freightClassType.value,
            loadValue: this.loadValue.value,
            isHazmat: !!this.isHazmat.value,
            identificationNumber: this.isHazmat.value ? this.identificationNumber.value : null,
            properShippingName: this.isHazmat.value ? this.properShippingName.value : null,
            hazardClass: this.isHazmat.value ? this.hazardClass.value : null,
            packingGroup: this.isHazmat.value ? this.packingGroup.value : null,
            emergencyContact: this.isHazmat.value ? this.emergencyContact.value : null,
        };
    }
}
