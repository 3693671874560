import { Component, Input, OnInit, OnChanges, SimpleChanges, AfterViewInit, ViewChild, OnDestroy } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';

import { filter, map, Subscription } from 'rxjs';

import { MenuItem } from 'primeng/api';

import { ScrollPanel } from 'primeng/scrollpanel';

import { ThemeComponent } from './theme.component';

import { MenuService } from '../services/menu.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

@Component({
    selector: 'app-theme-menu',
    templateUrl: './theme.menu.component.html'
})
export class ThemeMenuComponent implements OnInit, AfterViewInit, OnDestroy {

    @Input() reset: boolean = false;

    model: MenuItem[] = [];
    loading: boolean = true;

    private menuSubscription: Subscription | null = null;

    @ViewChild('layoutMenuScroller', { static: false }) layoutMenuScrollerViewChild: ScrollPanel | null = null;

    constructor(public app: ThemeComponent, private menuService: MenuService) {
    }

    ngOnInit(): void {
        this.menuSubscription = this.menuService.FullMenu.subscribe(menu => {
            this.model = menu;
            this.loading = false;
        });
    }

    ngAfterViewInit(): void {
        setTimeout(() => { this.layoutMenuScrollerViewChild?.moveBar(); }, 100);
    }

    ngOnDestroy(): void {
        if (this.menuSubscription)
            this.menuSubscription.unsubscribe();
    }

    onMenuClick(_event: any): void {
        //this.app.onMenuClick(event);
    }
}

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: '[app-theme-submenu]',
    templateUrl: './theme.submenu.component.html',
    animations: [
        trigger('children', [
            state('hiddenAnimated', style({
                height: '0px'
            })),
            state('visibleAnimated', style({
                height: '*'
            })),
            state('visible', style({
                height: '*',
                'z-index': 100
            })),
            state('hidden', style({
                height: '0px',
                'z-index': '*'
            })),
            transition('visibleAnimated => hiddenAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
            transition('hiddenAnimated => visibleAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
        ])
    ]
})
export class ThemeSubMenuComponent {
    @Input() item: MenuItem | MenuItem[] = [];

    @Input() root: boolean = false;

    @Input() visible: boolean = false;

    get items(): MenuItem[] {
        return Array.isArray(this.item)
            ? <MenuItem[]> this.item
            : (<MenuItem> this.item).items ?? [];
    }

    _parentActive: boolean = false;

    _reset: boolean = false;

    constructor(
        public app: ThemeComponent,
        public appMenu: ThemeMenuComponent,
        private router: Router,
        private activatedRoute: ActivatedRoute) {
    }

    itemClick(event: Event, item: MenuItem, index: number): void {
        if (this.root) {
            this.app.menuHoverActive = !this.app.menuHoverActive;
        }
        // avoid processing disabled items
        if (item.disabled) {
            event.preventDefault();
            return;
        }

        // execute command
        if (item.command) {
            item.command({ originalEvent: event, item });
        }

        // prevent hash change
        if (item.items || (!item.url && !item.routerLink)) {
            setTimeout(() => {
                this.appMenu.layoutMenuScrollerViewChild?.moveBar();
            }, 450);
            event.preventDefault();
        }

        // hide menu
        if (!item.items) {
            this.app.resetMenu = false;

            this.app.overlayMenuActive = false;
            this.app.staticMenuMobileActive = false;
            this.app.menuHoverActive = !this.app.menuHoverActive;
        }
    }

    onMouseEnter(index: number): void {
    }

    isActive(item: MenuItem): boolean {
        return item.routerLink
            ? this.router.isActive(item.routerLink, {
                paths: 'subset', queryParams: 'subset', fragment: 'ignored', matrixParams: 'ignored'
            })
            : false;
    }

    @Input() get reset(): boolean {
        return this._reset;
    }

    set reset(val: boolean) {
        this._reset = val;
    }

    @Input() get parentActive(): boolean {
        return this._parentActive;
    }

    set parentActive(val: boolean) {
        this._parentActive = val;
    }
}
