import { Component, OnInit, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'error-block',
    templateUrl: './error-block.component.html',
    styleUrls: ['./error-block.component.scss']
})
export class ErrorBlockComponent implements OnInit {

    @Input() field!: AbstractControl;
    @Input() messages!: { [key: string]: string };

    keys = Object.keys;

    constructor() { }

    ngOnInit(): void {
    }

}
