import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { filterNone } from '../helpers/custom-operators';
import { SupplierContext } from '../models/supplier';

@Injectable({
    providedIn: 'root'
})
export class ContextService {

    private _context: BehaviorSubject<SupplierContext[] | null> = new BehaviorSubject<SupplierContext[] | null>(null);

    get SupplierContext(): Observable<SupplierContext[]> {
        return this._context.pipe(filterNone());
    }

    constructor(private http: HttpClient) {
        this.http.get<SupplierContext[]>(environment.apiBaseUrl + 'Context').subscribe(context => {
            this._context.next(context);
        });
    }
}
