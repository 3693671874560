import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { MenuService } from '../services/menu.service';

import { MenuItem } from 'primeng/api';

@Component({
    selector: 'app-theme-breadcrumb',
    templateUrl: './theme.breadcrumb.component.html'
})
export class ThemeBreadcrumbComponent implements OnDestroy {

    subscription: Subscription;

    items: MenuItem[] = [];

    constructor(public menuService: MenuService) {
        this.subscription = menuService.Breadcrumbs.subscribe(response => {
            this.items = response;
        });
    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
}
