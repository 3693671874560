<h1>Manage Supplier Groups</h1>

<p-card header="Supplier Group">
  <form (submit)="saveSupplier()" [formGroup]="supplierForm">
    <div class="p-grid">
      <div class="p-col p-lg-4 p-xl-3 form-group">
        <label for="supplierId">Select Group</label>
        <p-dropdown
          inputId="supplierId"
          [options]="suppliers"
          formControlName="supplierId"
          [filter]="true"
          [editable]="false"
          appendTo="body">
        </p-dropdown>
      </div>
      <div class="p-col p-lg-4 p-xl-3 form-group">
        <label for="supplierName" class="required">Supplier Group Name</label>
        <div class="p-inputgroup">
          <input pInputText type="text"
            formControlName="supplierName"
            maxlength="100"
            required />

          <button type="submit" pButton
            [disabled]="!supplierForm.valid"
            [ngClass]="{ 'p-button-success' : !supplierForm.controls.supplierId.value }"
            [icon]="supplierForm.controls.supplierId.value ? 'pi pi-save' : 'pi pi-plus'">
          </button>

          <button type="button" pButton
            icon="pi pi-times"
            class="p-button-danger"
            (click)="deleteSupplier(); $event.preventDefault()">
          </button>
        </div>
        <error-block
          [field]="supplierForm.get('supplierName')!"
          [messages]="{
            'required': 'Supplier Name is required.',
            'maxLength': 'Supplier Name must be shorter than 100 characters.'
          }">
        </error-block>
      </div>
    </div>
  </form>
</p-card>

<div class="p-grid" *ngIf="showAssignments">
  <div class="p-col-6 p-lg-4">
    <div>
      <p-card header="Suppliers">
        <p-table [value]="accounts">
          <ng-template pTemplate="header">
            <tr>
              <th class="idcol">ID</th>
              <th>Name</th>
              <th></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-item>
            <tr>
              <td>{{ item.id }}</td>
              <td>{{ item.name }}</td>
              <td>
                <button type="button" pButton class="p-button-danger"
                  icon="pi pi-times"
                  (click)="removeAccount(item.id)">
                </button>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="footer">
            <tr>
              <td>
                <form (submit)="addAccount(); $event.preventDefault();">
                  <input type="text" pInputText [formControl]="newAccountId" appNumeric required maxlength="20" />
                </form>
              </td>
              <td>{{ newAccountName }}</td>
              <td>
                <button type="button" pButton class="p-button-success"
                  icon="pi pi-plus"
                  [disabled]="!newAccountId.valid"
                  (click)="addAccount(); $event.preventDefault();">
                </button>
              </td>
            </tr>
            <tr *ngIf="!newAccountId.valid && !newAccountId.pristine">
              <td colspan="3">
                <error-block [field]="newAccountId"
                  [messages]="{
                    'required': 'ID is required',
                    'pattern': 'ID must be a number',
                    'alreadyExists': 'ID has already been assigned.',
                    'hasName': 'Must match an existing Supplier.'
                  }">
                </error-block>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </p-card>
    </div>
    <div>
      <p-card header="Customers">
        <p-table [value]="customers">
          <ng-template pTemplate="header">
            <tr>
              <th class="idcol">ID</th>
              <th>Name</th>
              <th></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-item>
            <tr>
              <td>{{ item.id }}</td>
              <td>{{ item.name }}</td>
              <td>
                <button type="button" pButton class="p-button-danger"
                  icon="pi pi-times"
                  (click)="removeCustomer(item.id)">
                </button>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="footer">
            <tr>
              <td>
                <form (submit)="addCustomer(); $event.preventDefault();">
                  <input type="text" pInputText [formControl]="newCustomerId" appNumeric required maxlength="20" />
                </form>
              </td>
              <td>{{ newCustomerName }}</td>
              <td>
                <button type="button" pButton class="p-button-success"
                  icon="pi pi-plus"
                  [disabled]="!newCustomerId.valid"
                  (click)="addCustomer(); $event.preventDefault();">
                </button>
              </td>
            </tr>
            <tr *ngIf="!newCustomerId.valid && !newCustomerId.pristine">
              <td colspan="3">
                <error-block [field]="newCustomerId"
                  [messages]="{
                    'required': 'ID is required',
                    'pattern': 'ID must be a number',
                    'alreadyExists': 'ID has already been assigned.',
                    'hasName': 'Must match an existing Customer.'
                }">
                </error-block>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </p-card>
    </div>
  </div>
  <div class="p-col-6 p-lg-4">
    <div>
      <p-card header="Pickup Locations">
        <p-table [value]="pickupLocations">
          <ng-template pTemplate="header">
            <tr>
              <th class="idcol">ID</th>
              <th>Name</th>
              <th></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-item>
            <tr>
              <td>{{ item.id }}</td>
              <td>{{ item.name }}</td>
              <td>
                <button type="button" pButton class="p-button-danger"
                  icon="pi pi-times"
                  (click)="removePickupLocation(item.id)">
                </button>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="footer">
            <tr>
              <td>
                <form (submit)="addPickupLocation(); $event.preventDefault();">
                  <input type="text" pInputText [formControl]="newPickupLocationId" appNumeric required maxlength="20" />
                </form>
              </td>
              <td>{{ newPickupName }}</td>
              <td>
                <button type="button" pButton class="p-button-success"
                  icon="pi pi-plus"
                  [disabled]="!newPickupLocationId.valid"
                  (click)="addPickupLocation(); $event.preventDefault();">
                </button>
              </td>
            </tr>
            <tr *ngIf="!newPickupLocationId.valid && !newPickupLocationId.pristine">
              <td colspan="3">
                <error-block [field]="newPickupLocationId"
                  [messages]="{
                    'required': 'ID is required',
                    'pattern': 'ID must be a number',
                    'alreadyExists': 'ID has already been assigned.',
                    'hasName': 'Must match an existing Location.'
                  }">
                </error-block>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </p-card>
    </div>
    <div>
      <p-card header="Delivery Locations">
        <p-table [value]="deliveryLocations">
          <ng-template pTemplate="header">
            <tr>
              <th class="idcol">ID</th>
              <th>Name</th>
              <th></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-item>
            <tr>
              <td>{{ item.id }}</td>
              <td>{{ item.name }}</td>
              <td>
                <button type="button" pButton class="p-button-danger"
                  icon="pi pi-times"
                  (click)="removeDeliveryLocation(item.id)">
                </button>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="footer">
            <tr>
              <td>
                <form (submit)="addDeliveryLocation(); $event.preventDefault();">
                  <input type="text" pInputText [formControl]="newDeliveryLocationId" appNumeric required maxlength="20" />
                </form>
              </td>
              <td>{{ newDeliveryName }}</td>
              <td>
                <button type="button" pButton class="p-button-success"
                  icon="pi pi-plus"
                  [disabled]="!newDeliveryLocationId.valid"
                  (click)="addDeliveryLocation(); $event.preventDefault();">
                </button>
              </td>
            </tr>
            <tr *ngIf="!newDeliveryLocationId.valid && !newDeliveryLocationId.pristine">
              <td colspan="3">
                <error-block [field]="newDeliveryLocationId"
                  [messages]="{
                    'required': 'ID is required',
                    'pattern': 'ID must be a number',
                    'alreadyExists': 'ID has already been assigned.',
                    'hasName': 'Must match an existing Location.'
                  }">
                </error-block>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </p-card>
    </div>
  </div>
  <div class="p-col-6 p-lg-4">
    <p-card header="Users">
      <div>
        <p-table [value]="users">
          <ng-template pTemplate="header">
            <tr>
              <th>Email</th>
              <th></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-item>
            <tr>
              <td>{{ item }}</td>
              <td>
                <button type="button" pButton class="p-button-danger"
                  icon="pi pi-times"
                  (click)="removeUser(item)">
                </button>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="footer">
            <tr>
              <td>
                <form (submit)="addUser(); $event.preventDefault();">
                  <input type="text" pInputText [formControl]="newUserEmail" required maxlength="100" />
                </form>
              </td>
              <td>
                <button type="button" pButton class="p-button-success"
                  icon="pi pi-plus"
                  [disabled]="!newUserEmail.valid"
                  (click)="addUser(); $event.preventDefault();">
                </button>
              </td>
            </tr>
            <tr *ngIf="!newUserEmail.valid && !newUserEmail.pristine">
              <td colspan="3">
                <error-block [field]="newUserEmail"
                  [messages]="{
                    'required': 'User Email is required',
                    'alreadyExists': 'User Email has already been assigned.',
                    'email': 'User Email must be en email address'
                  }">
                </error-block>
              </td>
            </tr>
        </ng-template>
      </p-table>
    </div>
  </p-card>
  </div>
</div>
