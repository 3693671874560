<div class="layout-sidebar" (click)="onMenuClick($event)">
    <div class="layout-menu-title">MAIN MENU</div>
    <p-scrollPanel #layoutMenuScroller>
        <div class="menu-scroll-content">
            <ul app-theme-submenu [item]="model" [root]="true" class="layout-menu" [visible]="true" [reset]="reset" [parentActive]="true"></ul>
            <div *ngIf="loading" style="text-align: center;">
                <i class="pi pi-spin pi-spinner"></i>
            </div>
        </div>
    </p-scrollPanel>
</div>
