import { NgModule } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ExtraOptions, NavigationError, Router, RouterModule, Routes } from '@angular/router';
import { filter } from 'rxjs';
import { AuthGuard } from './guards/auth.guard';
import { FeatureGuard } from './guards/feature.guard';
import { CurrentOrdersComponent } from './pages/current-orders/current-orders.component';
import { HomeComponent } from './pages/home/home.component';
import { UnauthorizedComponent } from './pages/unauthorized/unauthorized.component';
import { MenuService } from './services/menu.service';
import { LoadRequestComponent } from './pages/order-form/load-request.component';
import { SupplierManagementComponent } from './pages/supplier-admin/supplier-admin.component';

const routes: Routes = [
    {
        path: '',
        canActivate: [AuthGuard, FeatureGuard],
        canActivateChild: [AuthGuard, FeatureGuard],
        children: [
            {
                path: '',
                component: HomeComponent,
                data: {
                    label: 'Home',
                    menuOrder: 1
                }
            },
            {
                path: 'request',
                component: LoadRequestComponent,
                data: {
                    label: 'Request Load',
                    menuOrder: 2,
                }
            },
            {
                path: 'current',
                component: CurrentOrdersComponent,
                data: {
                    label: 'Current Orders',
                    menuOrder: 3
                }
            },
            {
                path: 'management/suppliers',
                component: SupplierManagementComponent,
                data: {
                    roles: [ 'supplierlink-admin', 'developers' ],
                    label: 'Manage Suppliers',
                    menuOrder: 4
                }
            },
            {
                path: 'unauthorized',
                component: UnauthorizedComponent,
                data: {
                    label: 'Unauthorized'
                }
            }
        ]
    }
];

const routeSettings: ExtraOptions = {
    //enableTracing: !environment.production,
    useHash: true
};

@NgModule({
    imports: [RouterModule.forRoot(routes, routeSettings)],
    exports: [RouterModule]
})
export class AppRoutingModule {
    constructor(private router: Router, private title: Title, private menuService: MenuService) {
        this.menuService.Breadcrumbs.subscribe(breadcrumbs => {
            const t = breadcrumbs.reduce((agg, current) => agg ? `${agg} / ${current.label}` : current.label ?? '', '');

            this.title.setTitle(`Propak SupplierLink - ${t}`);
        });

        this.router.events.pipe(filter(evt => evt instanceof NavigationError)).subscribe((event: any) => {
            console.error('Navigation Error');
            console.error({ event });
        });
    }
}
