import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivateChild } from '@angular/router';
import { environment } from '@environment';

import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class FeatureGuard implements CanActivate, CanActivateChild {

    constructor() {}

    canActivate(route: ActivatedRouteSnapshot, _state: RouterStateSnapshot):
            Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

        const featureChecks: boolean[] = [];

        let r: ActivatedRouteSnapshot | null = route;

        while (r) {
            if ('feature' in r.data)
                featureChecks.push(environment.features[r.data['feature']]);

            r = r.parent;
        }

        if (featureChecks.length)
            return featureChecks.every(f => f);
        else
            return true;
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
            Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.canActivate(route, state);
    }
}
