import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { DateTime } from 'luxon';
import { map, Observable } from 'rxjs';
import { OrderInfo } from '../models/order-info';

@Injectable({
    providedIn: 'root'
})
export class CurrentOrdersService {

    constructor(private http: HttpClient) { }

    GetCurrentOrders(): Observable<OrderInfo[]> {
        return this.http.get<OrderInfo[]>(environment.apiBaseUrl + 'Orders').pipe(map(orders => {
            for(const order of orders) {
                if (order.pickupAppointmentStart)
                    order.pickupAppointmentStart.date = DateTime.fromISO(<any> order.pickupAppointmentStart.date);
                if (order.pickupAppointmentEnd)
                    order.pickupAppointmentEnd.date = DateTime.fromISO(<any> order.pickupAppointmentEnd.date);
                if (order.pickupArrived)
                    order.pickupArrived.date = DateTime.fromISO(<any> order.pickupArrived.date);
                if (order.pickupDeparted)
                    order.pickupDeparted.date = DateTime.fromISO(<any> order.pickupDeparted.date);
                if (order.deliveryAppointmentStart)
                    order.deliveryAppointmentStart.date = DateTime.fromISO(<any> order.deliveryAppointmentStart.date);
                if (order.deliveryAppointmentEnd)
                    order.deliveryAppointmentEnd.date = DateTime.fromISO(<any> order.deliveryAppointmentEnd.date);
                if (order.deliveryArrived)
                    order.deliveryArrived.date = DateTime.fromISO(<any> order.deliveryArrived.date);
                if (order.deliveryDeparted)
                    order.deliveryDeparted.date = DateTime.fromISO(<any> order.deliveryDeparted.date);
            }

            return orders;
        }));
    }
}
