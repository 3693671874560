<h1>Home</h1>

<div class="p-grid">
  <div class="p-col-3">
    <p-card header="Links">
      <ul>
        <li><a routerLink="request">Request Load</a></li>
        <li><a routerLink="current">Current Orders</a></li>
      </ul>
    </p-card>
  </div>
</div>


<div class="p-grid" *ngIf="canSeeDebug">
  <div class="p-col">
    <p-card>
      <pre>{{ response | json }}</pre>
    </p-card>
  </div>
  <div class="p-col">
    <p-card>
      <pre>{{ lookups | json }}</pre>
    </p-card>
  </div>
</div>
