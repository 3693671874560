import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { MessageService, Message } from 'primeng/api';

import { AuthService } from '../services/auth.service';


interface ServerValidationResponse {
    errors: { [key: string]: string | string[] };
    title: string;
    status: number;
    traceId: string;
}

@Injectable({
    providedIn: 'root'
})
export class ApiInterceptor implements HttpInterceptor {

    readonly _connectionErrorMsg: Message = {
        severity: 'error',
        summary: 'No Connection',
        detail: 'Could not reach server. Check your internet connection and try again.'
    };

    readonly _badRequestMsg: Message = {
        severity: 'warn',
        summary: 'Invalid Request',
        detail: 'Please correct the following errors:',
        sticky: true
    };

    readonly _unauthorizedMsg: Message = {
        severity: 'warn',
        summary: 'Unauthorized',
        detail: 'You have been logged out. Please log in again.'
    };

    readonly _forbiddenMsg: Message = {
        severity: 'warn',
        summary: 'Action Not Allowed',
        detail: 'You do not have permission to perform this action.'
    };

    readonly _notFoundMsg: Message = {
        severity: 'warn',
        summary: 'Missing Resource',
        detail: 'Could not find server endpoint. Please contact support.'
    };

    readonly _apiErrorMsg: Message = {
        severity: 'error',
        summary: 'Server Error',
        detail: 'The server has encountered an error. If you keep getting this message, contact support.'
    };

    constructor(
        private authService: AuthService,
        private messageService: MessageService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(catchError((error: HttpErrorResponse) => {
            if (error.error instanceof ErrorEvent) {
                this.messageService.add(this._connectionErrorMsg);
            }
            else if (error.status === 400) {
                const response: ServerValidationResponse = error.error;

                const errors: string[] = Object.values(response.errors).flat(1);

                this.messageService.add({
                    ...this._badRequestMsg,
                    data: errors
                });
            }
            else if (error.status === 401) { // unauthorized
                this.messageService.add(this._unauthorizedMsg);

                // slight delay so users can see the toast
                setTimeout(() => {
                    this.authService.logout();
                }, 3000);
            }
            else if (error.status === 403) { // forbidden
                this.messageService.add(this._forbiddenMsg);
            }
            else if (error.status === 404) { // not found
                this.messageService.add(this._notFoundMsg);
            }
            else if (error.status >= 500 || error.status === 424) { // API error
                const response: ServerValidationResponse = error.error;

                const errors: string[] = Object.values(response.errors).flat(1);

                this.messageService.add({
                    ...this._apiErrorMsg,
                    data: errors
                });
            }

            return throwError(error);
        }));
    }
}
