import { AbstractControl, FormControl } from '@angular/forms';
import { concat, defer, filter, map, Observable, of, OperatorFunction } from 'rxjs';

export const once = <T>(action: (item: T) => void): OperatorFunction<T, T> => map((item: T, index: number) => {
    if (index === 0)
        action(item);

    return item;
});

export const tapOnce = once;

export const mapOnce = <T>(action: (item: T) => T): OperatorFunction<T, T> => map((item: T, index: number) => {
    if (index === 0)
        return action(item);

    return item;
});

export function filterNone<T>(): (source: Observable<T>) => Observable<NonNullable<T>> {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return source => source.pipe(
        filter<T, NonNullable<T>>((value: T): value is NonNullable<T> => value !== null && value !== undefined));
}

export function streamControlValue<T>(control: AbstractControl<T, T>): Observable<T> {
    return concat(
        defer(() => of(control.value)),
        control.valueChanges);
}
