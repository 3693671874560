<h1>Current Orders</h1>

<p-card>
  <p-table #orderstable
      [value]="orders"
      [paginator]="true"
      [rows]="20"
      [autoLayout]="true"
      [customSort]="true"
      [globalFilterFields]="[
        'customId',
        'status',
        'pickup.name',
        'delivery.name',
        'carrier.name',
        'customer.name',
        'supplier.name',
        'poNumsJoined',
        'carriersString',
        'schedPickupString',
        'actualPickupString',
        'schedDeliveryString',
        'actualDeliveryString'
      ]"
      (sortFunction)="customSort($event)">
    <ng-template pTemplate="caption">
      <div class="flex">
        <span class="p-input-icon-left ml-auto">
          <i class="pi pi-search"></i>
          <input type="text" pInputText (input)="onSearch($event)" placeholder="Search" />
        </span>
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th pSortableColumn="customId">Order Id <p-sortIcon field="customId"></p-sortIcon></th>
        <th pSortableColumn="po">PO #(s) <p-sortIcon field="po"></p-sortIcon></th>
        <th pSortableColumn="status">Status <p-sortIcon field="status"></p-sortIcon></th>
        <th pSortableColumn="pickup">Pickup Location <p-sortIcon field="pickup"></p-sortIcon></th>
        <th pSortableColumn="schedpickup">Scheduled Pickup <p-sortIcon field="schedpickup"></p-sortIcon></th>
        <th pSortableColumn="actualpickup">Actual Pickup <p-sortIcon field="actualpickup"></p-sortIcon></th>
        <th pSortableColumn="delivery">Delivery Location <p-sortIcon field="delivery"></p-sortIcon></th>
        <th pSortableColumn="scheddelivery">Scheduled Delivery <p-sortIcon field="scheddelivery"></p-sortIcon></th>
        <th pSortableColumn="actualdelivery">Actual Delivery <p-sortIcon field="actualdelivery"></p-sortIcon></th>
        <th pSortableColumn="carriers">Carrier(s) <p-sortIcon field="carriers"></p-sortIcon></th>
        <th pSortableColumn="customer">Customer <p-sortIcon field="customer"></p-sortIcon></th>
        <th pSortableColumn="supplier">Supplier <p-sortIcon field="supplier"></p-sortIcon></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="12" class="centered">
          <span *ngIf="loadingOrders; else noOrders">Loading Orders...</span>
          <ng-template #noOrders>There are no orders to display.</ng-template>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-order>
      <tr>
        <td>{{ order.customId }}</td>
        <td class="po-column" [ngClass]="{'has-more': order.purchaseOrderNumbers.length > 1 }" (click)="showPoOverlay($event, order);">
          {{ order.purchaseOrderNumbers[0] }}
          <span *ngIf="order.purchaseOrderNumbers.length > 1">(+{{ order.purchaseOrderNumbers.length - 1 }} more)</span>
        </td>
        <td>{{ order.status }}</td>
        <td>{{ order.pickup?.name }}</td>
        <td>{{ order.schedPickupString }}</td>
        <td>{{ order.actualPickupString }}</td>
        <td>{{ order.delivery?.name }}</td>
        <td>{{ order.schedDeliveryString }}</td>
        <td>{{ order.actualDeliveryString }}</td>
        <td [ngClass]="{'has-more': order.carriers.length > 1 }" (click)="showCarriersOverlay($event, order);">
          {{ order.carriers[0]?.name }}
          <span *ngIf="order.carriers.length > 1">(+{{ order.carriers.length - 1 }} more)</span>
        </td>
        <td>{{ order.customer?.name }}</td>
        <td>{{ order.supplier?.name }}</td>
      </tr>
    </ng-template>
  </p-table>
</p-card>

<p-overlayPanel #listOverlay>
  <ng-template pTemplate>
    <ul *ngFor="let s of overlayStrings">
      <li>{{ s }}</li>
    </ul>
  </ng-template>
</p-overlayPanel>
