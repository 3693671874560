import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormControlState, FormGroup, Validators } from '@angular/forms';
import { MessageService, SelectItem } from 'primeng/api';
import { combineLatest, concatMap, debounceTime, lastValueFrom, map, mergeMap, Observable, of, Subscription, switchMap, tap } from 'rxjs';
import { streamControlValue } from 'src/app/helpers/custom-operators';
import { LocationSummary } from 'src/app/models/location';
import { Supplier, SupplierAssignments } from 'src/app/models/supplier';
import { ActivityService } from 'src/app/services/activity.service';
import { SupplierManagementService } from 'src/app/services/supplier-management.service';

@Component({
  selector: 'app-supplier-admin',
  templateUrl: './supplier-admin.component.html',
  styleUrls: ['./supplier-admin.component.scss']
})
export class SupplierManagementComponent implements OnInit, OnDestroy {

    suppliers: SelectItem[] = [];

    supplierForm: FormGroup<{
        supplierId: FormControl<number | null>,
        supplierName: FormControl<string | null>
    }> = new FormGroup({
        supplierId: new FormControl<number | null>(null),
        supplierName: new FormControl<string>('', [ Validators.required, Validators.maxLength(100) ])
    });

    showAssignments: boolean = false;
    assignmentsLoading: boolean = false;

    accounts: { id: number; name: string; }[] = [];
    customers: { id: number; name: string; }[] = [];
    pickupLocations: { id: number; name: string; }[] = [];
    deliveryLocations: { id: number; name: string; }[] = [];
    users: string[] = [];

    newAccountId: FormControl<number | null> = new FormControl<number | null>(null, [
            Validators.required,
            Validators.pattern(/^\d+$/),
            control => this.accounts.some(a => a.id === +control.value)
                    ? { alreadyExists: true }
                    : null
        ], [
           async control => await lastValueFrom(this.getAccountName(+control.value))
                    ? null
                    : { hasName: true }
        ]);
    newCustomerId: FormControl<number | null> = new FormControl<number | null>(null, [
            Validators.required,
            Validators.pattern(/^\d+$/),
            control => this.customers.some(a => a.id === +control.value)
                    ? { alreadyExists: true }
                    : null
        ], [
            async control => await lastValueFrom(this.getCustomerName(+control.value))
                    ? null
                    : { hasName: true }
        ]);
    newPickupLocationId: FormControl<number | null> = new FormControl<number | null>(null, [
            Validators.required,
            Validators.pattern(/^\d+$/),
            control => this.pickupLocations.some(a => a.id === +control.value)
                    ? { alreadyExists: true }
                    : null
        ], [
            async control => await lastValueFrom(this.getPickupLocationName(+control.value))
                    ? null
                    : { hasName: true }
        ]);
    newDeliveryLocationId: FormControl<number | null> = new FormControl<number | null>(null, [
            Validators.required,
            Validators.pattern(/^\d+$/),
            control => this.deliveryLocations.some(a => a.id === +control.value)
                    ? { alreadyExists: true }
                    : null
        ], [
            async control => await lastValueFrom(this.getDeliveryLocationName(+control.value))
                    ? null
                    : { hasName: true }
        ]);

    newUserEmail: FormControl<string | null> = new FormControl<string | null>('', [
        Validators.required,
        Validators.email,
        control => this.users.includes(control.value)
                ? { alreadyExists: true }
                : null
    ]);

    newAccountName: string = '';
    newCustomerName: string = '';
    newPickupName: string = '';
    newDeliveryName: string = '';

    subscriptions: Subscription[] = [];

    constructor(
        private supplierSvc: SupplierManagementService,
        private activitySvc: ActivityService,
        private messageSvc: MessageService) {}

    ngOnInit(): void {
        this.supplierSvc.GetAll()
            .pipe(this.activitySvc.Wait('Supplier Management supplier list'))
            .subscribe(suppliers => {
                this.suppliers = this.sorted([
                    { value: null, label: '-- Create New -- ' },
                    ...suppliers.map(s => ({ value: s.id, label: s.name }))
                ]);
            });

        this.subscriptions = [
            this.supplierForm.controls.supplierId.valueChanges
                .pipe(mergeMap(supplierId => {
                    if (supplierId)
                        this.supplierForm.controls.supplierName.setValue(
                            this.suppliers.find(s => s.value === supplierId)?.label ?? '',
                            { emitEvent: false });
                    else
                        this.supplierForm.controls.supplierName.setValue('');

                    if (supplierId) {
                        this.showAssignments = true;
                        this.assignmentsLoading = true;
                        return this.supplierSvc.GetDetail(supplierId);
                    }
                    else {
                        this.showAssignments = false;
                        this.assignmentsLoading = true;
                        return of(null);
                    }
                }))
                .subscribe(assignments => {
                    this.assignmentsLoading = false;

                    this.accounts = assignments?.supplierAccounts.map(a => ({ id: a.accountId, name: '' })) ?? [];
                    this.customers = assignments?.supplierCustomers.map(a => ({ id: a.customerId, name: '' })) ?? [];
                    this.pickupLocations = assignments?.supplierPickupLocations.map(a => ({ id: a.locationId, name: '' })) ?? [];
                    this.deliveryLocations = assignments?.supplierDeliveryLocations.map(a => ({ id: a.locationId, name: '' })) ?? [];
                    this.users = assignments?.supplierUsers.map(u => u.userEmail) ?? [];

                    this.accounts.forEach(a => this.getAccountName(a.id).subscribe(n => a.name = n));
                    this.customers.forEach(a => this.getCustomerName(a.id).subscribe(n => a.name = n));
                    this.pickupLocations.forEach(a => this.getPickupLocationName(a.id).subscribe(n => a.name = n));
                    this.deliveryLocations.forEach(a => this.getDeliveryLocationName(a.id).subscribe(n => a.name = n));

                    this.newAccountId.reset();
                    this.newCustomerId.reset();
                    this.newPickupLocationId.reset();
                    this.newDeliveryLocationId.reset();
                    this.newUserEmail.reset('');
                }),

            streamControlValue<number | null>(this.newAccountId)
                .pipe(concatMap(id => id ? this.getAccountName(id) : of('')))
                .subscribe(n => this.newAccountName = n || '<none>'),

            streamControlValue<number | null>(this.newCustomerId)
                .pipe(concatMap(id => id ? this.getCustomerName(id) : of('')))
                .subscribe(n => this.newCustomerName = n || '<none>'),

            streamControlValue<number | null>(this.newPickupLocationId)
                .pipe(concatMap(id => id ? this.getPickupLocationName(id) : of('')))
                .subscribe(n => this.newPickupName = n || '<none>'),

            streamControlValue<number | null>(this.newDeliveryLocationId)
                .pipe(concatMap(id => id ? this.getDeliveryLocationName(id) : of('')))
                .subscribe(n => this.newDeliveryName = n || '<none>')
        ];
    }

    ngOnDestroy(): void {
        this.suppliers = [];

        this.subscriptions.forEach(s => s.unsubscribe());
    }

    saveSupplier(): void {
        if (!this.supplierForm.valid) {
            this.supplierForm.updateValueAndValidity();
            this.messageSvc.add({
                summary: 'Cannot Save Supplier Name',
                detail: 'Please correct the listed errors.',
                severity: 'warning'
            });
            return;
        }

        const post: Supplier = {
            id: this.supplierForm.controls.supplierId.value,
            name: this.supplierForm.controls.supplierName.value ?? ''
        };

        if (post.id === null) {
            this.supplierSvc.Create(post.name).subscribe(supplier => {
                this.suppliers = this.sorted([
                    ...this.suppliers,
                    { value: supplier.id, label: supplier.name }
                ]);

                setTimeout(() => this.supplierForm.controls.supplierId.setValue(supplier.id));
            });
        }
        else {
            this.supplierSvc.Update(post).subscribe(supplier => {
                this.supplierForm.setValue({ supplierId: supplier.id, supplierName: supplier.name });

                const cur = this.suppliers.find(s => s.value === supplier.id);

                if (cur)
                    cur.label = supplier.name;

                this.suppliers = this.sorted(this.suppliers);
            });
        }
    }

    deleteSupplier(): void {
        const supplierId = this.supplierForm.controls.supplierId.value ?? 0;
        this.supplierSvc.Delete(supplierId).subscribe({
            next: () => {
                this.messageSvc.add({
                    summary: 'Supplier Group Deleted.',
                    detail: 'The Supplier Group has been deleted.',
                    severity: 'warn'
                });
                this.suppliers = this.suppliers.filter(s => s.value !== supplierId);
                this.supplierForm.setValue({
                    supplierId: null,
                    supplierName: ''
                });
            },
            error: _ => {}
        });
    }

    sorted(items: SelectItem[]): SelectItem[] {
        return items.slice().sort((l, r) => (l.label ?? '').localeCompare(r.label ?? ''));
    }

    getAccountName(accountId: number): Observable<string> {
        return this.supplierSvc.GetAccountName(accountId);
    }

    removeAccount(accountId: number): void {
        combineLatest([
            this.getAccountName(accountId),
            this.supplierSvc.DeleteAccount(this.supplierForm.controls.supplierId.value ?? 0, accountId)
        ]).subscribe({
            next: ([accountName]) => {
                this.accounts = this.accounts.filter(a => a.id !== accountId);

                this.messageSvc.add({
                    summary: 'Account Removed',
                    detail: `${this.supplierForm.controls.supplierName.value} no longer has access to ${accountName}`,
                    severity: 'success'
                });
            },
            error: _err => {
            }
        });
    }

    addAccount(): void {
        if (!this.newAccountId.valid) {
            this.newAccountId.markAsTouched();
            this.newAccountId.updateValueAndValidity();
            this.messageSvc.add({
                summary: 'Cannot add Account',
                detail: 'Please correct the listed errors.',
                severity: 'warning'
            });
            return;
        }

        const accountId = +(this.newAccountId.value ?? 0);

        combineLatest([
            this.getAccountName(accountId),
            this.supplierSvc.AddAccount(this.supplierForm.controls.supplierId.value ?? 0, accountId)
        ]).subscribe({
            next: ([accountName]) => {
                this.accounts = [
                    ...this.accounts,
                    { id: accountId, name: accountName }
                ];

                this.newAccountId.reset();

                this.messageSvc.add({
                    summary: 'Account Added',
                    detail: `${this.supplierForm.controls.supplierName.value} now has access to ${accountName}`,
                    severity: 'success'
                });
            },
            error: _err => {
            }
        });
    }

    getCustomerName(customerId: number): Observable<string> {
        return this.supplierSvc.GetCustomerName(customerId);
    }

    removeCustomer(customerId: number): void {
        combineLatest([
            this.getCustomerName(customerId),
            this.supplierSvc.DeleteCustomer(this.supplierForm.controls.supplierId.value ?? 0, customerId)
        ]).subscribe({
            next: ([customerName]) => {
                this.customers = this.customers.filter(a => a.id !== customerId);

                this.messageSvc.add({
                    summary: 'Customer Removed',
                    detail: `${this.supplierForm.controls.supplierName.value} no longer has access to ${customerName}`,
                    severity: 'success'
                });
            },
            error: _err => {
            }
        });
    }

    addCustomer(): void {
        if (!this.newCustomerId.valid) {
            this.newCustomerId.markAsTouched();
            this.newCustomerId.updateValueAndValidity();
            this.messageSvc.add({
                summary: 'Cannot add Customer',
                detail: 'Please correct the listed errors.',
                severity: 'warning'
            });
            return;
        }

        const customerId = +(this.newCustomerId.value ?? 0);

        combineLatest([
            this.getCustomerName(customerId),
            this.supplierSvc.AddCustomer(this.supplierForm.controls.supplierId.value ?? 0, customerId)
        ]).subscribe({
            next: ([customerName]) => {
                this.customers = [
                    ...this.customers,
                    { id: customerId, name: customerName }
                ];

                this.newCustomerId.reset();

                this.messageSvc.add({
                    summary: 'Customer Added',
                    detail: `${this.supplierForm.controls.supplierName.value} now has access to ${customerName}`,
                    severity: 'success'
                });
            },
            error: _err => {
            }
        });
    }

    getLocationId(item: { locationId: number }): number {
        return item.locationId;
    }

    getPickupLocationName(locationId: number): Observable<string> {
        return this.supplierSvc.GetLocationName(locationId);

    }

    removePickupLocation(pickupLocationId: number): void {
        combineLatest([
            this.getPickupLocationName(pickupLocationId),
            this.supplierSvc.DeletePickupLocation(this.supplierForm.controls.supplierId.value ?? 0, pickupLocationId)
        ]).subscribe({
            next: ([locationName]) => {
                this.pickupLocations = this.pickupLocations.filter(a => a.id !== pickupLocationId);

                this.messageSvc.add({
                    summary: 'Pickup Location Removed',
                    detail: `${this.supplierForm.controls.supplierName.value} no longer has access to ${locationName}`,
                    severity: 'success'
                });
            },
            error: _err => {
            }
        });
    }

    addPickupLocation(): void {
        if (!this.newPickupLocationId.valid) {
            this.newPickupLocationId.markAsTouched();
            this.newPickupLocationId.updateValueAndValidity();
            this.messageSvc.add({
                summary: 'Cannot add Pickup Location',
                detail: 'Please correct the listed errors.',
                severity: 'warning'
            });
            return;
        }

        const locationId = +(this.newPickupLocationId.value ?? 0);

        combineLatest([
            this.getPickupLocationName(locationId),
            this.supplierSvc.AddPickupLocation(this.supplierForm.controls.supplierId.value ?? 0, locationId)
        ]).subscribe({
            next: ([locationName]) => {
                this.pickupLocations = [
                    ...this.pickupLocations,
                    { id: locationId, name: locationName }
                ];

                this.newPickupLocationId.reset();

                this.messageSvc.add({
                    summary: 'Pickup Location Added',
                    detail: `${this.supplierForm.controls.supplierName.value} now has access to ${locationName}`,
                    severity: 'success'
                });
            },
            error: _err => {
            }
        });
    }

    getDeliveryLocationName(locationId: number): Observable<string> {
        return this.supplierSvc.GetLocationName(locationId);
    }

    removeDeliveryLocation(deliveryLocationId: number): void {
        combineLatest([
            this.getDeliveryLocationName(deliveryLocationId),
            this.supplierSvc.DeleteDeliveryLocation(this.supplierForm.controls.supplierId.value ?? 0, deliveryLocationId)
        ]).subscribe({
            next: ([locationName]) => {
                this.deliveryLocations = this.deliveryLocations.filter(a => a.id !== deliveryLocationId);

                this.messageSvc.add({
                    summary: 'Delivery Location Removed',
                    detail: `${this.supplierForm.controls.supplierName.value} no longer has access to ${locationName}`,
                    severity: 'success'
                });
            },
            error: _err => {
            }
        });
    }

    addDeliveryLocation(): void {
        if (!this.newDeliveryLocationId.valid) {
            this.newDeliveryLocationId.markAsTouched();
            this.newDeliveryLocationId.updateValueAndValidity();
            this.messageSvc.add({
                summary: 'Cannot add Delivery Location',
                detail: 'Please correct the listed errors.',
                severity: 'warning'
            });
            return;
        }

        const locationId = +(this.newDeliveryLocationId.value ?? 0);

        combineLatest([
            this.getDeliveryLocationName(locationId),
            this.supplierSvc.AddDeliveryLocation(this.supplierForm.controls.supplierId.value ?? 0, locationId)
        ]).subscribe({
            next: ([locationName]) => {
                this.deliveryLocations = [
                    ...this.deliveryLocations,
                    { id: locationId, name: locationName }
                ];

                this.newDeliveryLocationId.reset();

                this.messageSvc.add({
                    summary: 'Delivery Location Added',
                    detail: `${this.supplierForm.controls.supplierName.value} now has access to ${locationName}`,
                    severity: 'success'
                });
            },
            error: _err => {
            }
        });
    }

    removeUser(userEmail: string): void {
        this.supplierSvc.DeleteUser(this.supplierForm.controls.supplierId.value ?? 0, userEmail).subscribe({
            next: () => {
                this.users = this.users.filter(e => e !== userEmail);

                this.messageSvc.add({
                    summary: 'User Removed',
                    detail: `${userEmail} no longer has access to ${this.supplierForm.controls.supplierName.value}`,
                    severity: 'success'
                });
            },
            error: _err => {
            }
        });
    }

    addUser(): void {
        if (!this.newUserEmail.valid) {
            this.newUserEmail.markAsTouched();
            this.newUserEmail.updateValueAndValidity();
            this.messageSvc.add({
                summary: 'Cannot add User to Supplier Group',
                detail: 'Please correct the listed errors.',
                severity: 'warning'
            });
            return;
        }

        const userEmail = this.newUserEmail.value ?? '';

        this.supplierSvc.AddUser(this.supplierForm.controls.supplierId.value ?? 0, userEmail).subscribe({
            next: () => {
                this.users = [
                    ...this.users,
                    userEmail
                ];

                this.newUserEmail.reset();

                this.messageSvc.add({
                    summary: 'User Added',
                    detail: `${userEmail} now has access to ${this.supplierForm.controls.supplierName.value}`,
                    severity: 'success'
                });
            },
            error: _err => {
            }
        });
    }
}
