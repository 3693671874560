import { Component, OnInit, OnDestroy, Input } from '@angular/core';

import { Subscription } from 'rxjs';

import { ActivityService } from '../services/activity.service';
import { AuthService } from '../services/auth.service';

@Component({
    selector: 'app-theme-root',
    templateUrl: './theme.component.html'
})
export class ThemeComponent implements OnInit, OnDestroy {

    @Input() buildNumber: string = '';

    topbarMenuActive: boolean = false;

    overlayMenuActive: boolean = false;

    staticMenuDesktopInactive: boolean = false;

    staticMenuMobileActive: boolean = false;

    menuClick: boolean = false;

    topbarItemClick: boolean = false;

    activeTopbarItem: any;

    resetMenu: boolean = false;

    menuHoverActive: boolean = false;

    showLoadingOverlay: boolean = false;

    authMessage: { summary: string, detail: string} | null = null;

    subscriptions: Subscription[] = [];

    constructor(private activityService: ActivityService, private authService: AuthService) {

    }

    ngOnInit(): void {
        this.subscriptions = [
            this.activityService.IsBusy.subscribe(busy => {
                setTimeout(() => { this.showLoadingOverlay = busy; });
            }),
            this.authService.AuthMessage.subscribe(msg => {
                setTimeout(() => { this.authMessage = msg; });
            })
        ];
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(s => { s.unsubscribe(); });
    }

    onLayoutClick(): void {
        if (!this.topbarItemClick) {
            this.activeTopbarItem = null;
            this.topbarMenuActive = false;
        }

        if (!this.menuClick) {
            if (this.overlayMenuActive || this.staticMenuMobileActive) {
                this.hideOverlayMenu();
            }

            this.menuHoverActive = false;
        }

        this.topbarItemClick = false;
        this.menuClick = false;
    }

    onMenuButtonClick(event: MouseEvent): void {
        this.menuClick = true;
        this.topbarMenuActive = false;

        if (this.isDesktop()) {
            this.staticMenuDesktopInactive = !this.staticMenuDesktopInactive;
        } else {
            this.staticMenuMobileActive = !this.staticMenuMobileActive;
        }

        event.preventDefault();
    }

    isMobile(): boolean {
        return window.innerWidth < 1025;
    }

    isDesktop(): boolean {
        return window.innerWidth > 1024;
    }

    isTablet(): boolean {
        const width = window.innerWidth;
        return width <= 1024 && width > 640;
    }

    hideOverlayMenu(): void {
        this.overlayMenuActive = false;
        this.staticMenuMobileActive = false;
    }
}
