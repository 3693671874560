import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Router, NavigationEnd, Route } from '@angular/router';
import { Observable, BehaviorSubject, filter, map } from 'rxjs';

import { MenuItem } from 'primeng/api';

import { AuthService } from './auth.service';
import { environment } from '@environment';

@Injectable({
    providedIn: 'root'
})
export class MenuService {
    private _fullMenu: BehaviorSubject<MenuItem[]>;
    private _breadcrumbsObservable: Observable<MenuItem[]>;

    public get Breadcrumbs(): Observable<MenuItem[]> {
        return this._breadcrumbsObservable;
    }

    get FullMenu(): Observable<MenuItem[]> {
        return this._fullMenu.asObservable().pipe(filter(menu => menu !== null));
    }

    constructor(private router: Router,
                private activatedRoute: ActivatedRoute,
                private authService: AuthService) {

        this._fullMenu = new BehaviorSubject<MenuItem[]>([]);

        this._breadcrumbsObservable = this.router.events.pipe(
            filter(event => event instanceof NavigationEnd),
            map(event => this.CreateBreadcrumbs(this.activatedRoute.snapshot)));

        this.authService.Roles.subscribe(roles => {
            const menu = this.getAppMenu(roles || []);

            this._fullMenu.next(menu);
        });
    }

    private flatten(routes: Route[]): Route[] {
        if (routes === undefined)
            return [];

        const children = routes.map(r => this.flatten(r.children ?? [])).flat(1);

        return [ ...routes, ...children ];
    }

    private find(needle: Route, haystack: Route[]): Route[] {
        if (!haystack || haystack.length === 0)
            return [];
        else if (haystack.includes(needle))
            return [needle];
        else
            return haystack
                .reduce<Route[] | null>((aggregate, h) => {
                    if (aggregate)
                        return aggregate;
                    const f = this.find(needle, h.children ?? []);
                    return f ? [h, ...f] : null;
                }, null)
                ?? [];
    }

    private getAppMenu(roles: string[]): MenuItem[] {
        const flat = this.flatten(this.router.config);

        const routes = flat
            .filter(r => r.data ? r.data['menuOrder'] : null)
            .filter(route => !route.data?.['roles'] || roles.some(role => route.data?.['roles'].includes(role)))
            .filter(r => !r.data || !r.data['feature'] || environment.features[r.data['feature']]);

        routes.sort((l, r) => (l.data?.['menuOrder'] ?? 0) - (r.data?.['menuOrder'] ?? 0));

        return routes.map(r => <MenuItem> {
            label: r.data?.['link'] || r.data?.['label'],
            routerLink: '/' + this.find(r, this.router.config).map(n => n.path).join('/')
        });
    }

    private CreateBreadcrumbs(route: ActivatedRouteSnapshot): MenuItem[] {
        const items: MenuItem[] = [];

        let node = route;

        while (node) {
            if (node.data && node.data['label'])
                items.push({
                    label: node.data['label'],
                    routerLink: this.CreateRouterLink(node)
                });

            node = node.children[0];
        }

        return items;
    }

    private CreateRouterLink(route: ActivatedRouteSnapshot): string {
        return route.pathFromRoot
            .map(v => v.url.map(segment => segment.toString()).join('/'))
            .join('/');
    }
}
