import { Component } from '@angular/core';
import { environment } from '@environment';

@Component({
    selector: 'app-root',
    template: '<app-theme-root *ngIf="show" [buildNumber]="buildNumber"></app-theme-root>',
})
export class AppComponent {
    show: boolean = false;
    buildNumber: string = environment.buildNumber;

    constructor() {
        // TODO do this once auth is finished and we're sure to show page
        this.show = true;
    }

}
