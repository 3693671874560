import { LocationStrategy } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { map, Observable } from 'rxjs';
import { Cacheable } from 'ts-cacheable';
import { url } from '../helpers/url';
import { Customer } from '../models/customer';
import { LocationSummary } from '../models/location';
import { Account, Supplier, SupplierAssignments } from '../models/supplier';

@Injectable({
    providedIn: 'root'
})
export class SupplierManagementService {

    constructor(private http: HttpClient) { }

    GetAll(): Observable<Supplier[]> {
        return this.http.get<Supplier[]>(environment.apiBaseUrl + 'Admin/Suppliers');
    }

    Create(supplierName: string): Observable<Supplier> {
        return this.http.post<Supplier>(
            environment.apiBaseUrl + 'Admin/Suppliers',
            JSON.stringify(supplierName),
            { headers: { 'Content-Type': 'application/json' } });
    }

    GetDetail(supplierId: number): Observable<SupplierAssignments> {
        return this.http.get<SupplierAssignments>(environment.apiBaseUrl + url`Admin/Suppliers/${supplierId}`);
    }

    Update(supplier: Supplier): Observable<Supplier> {
        return this.http.put<Supplier>(environment.apiBaseUrl + url`Admin/Suppliers/${supplier.id}`, supplier);
    }

    Delete(supplierId: number): Observable<void> {
        return this.http.delete<void>(environment.apiBaseUrl + url`Admin/Suppliers/${supplierId}`);
    }

    AddAccount(supplierId: number, accountId: number): Observable<void> {
        return this.http.put<void>(environment.apiBaseUrl + url`Admin/Suppliers/${supplierId}/accounts/${accountId}`, null);
    }

    DeleteAccount(supplierId: number, accountId: number): Observable<void> {
        return this.http.delete<void>(environment.apiBaseUrl + url`Admin/Suppliers/${supplierId}/accounts/${accountId}`);
    }

    AddCustomer(supplierId: number, customerId: number): Observable<void> {
        return this.http.put<void>(environment.apiBaseUrl + url`Admin/Suppliers/${supplierId}/customers/${customerId}`, null);
    }

    DeleteCustomer(supplierId: number, customerId: number): Observable<void> {
        return this.http.delete<void>(environment.apiBaseUrl + url`Admin/Suppliers/${supplierId}/customers/${customerId}`);
    }

    AddPickupLocation(supplierId: number, locationId: number): Observable<void> {
        return this.http.put<void>(environment.apiBaseUrl + url`Admin/Suppliers/${supplierId}/pickupLocations/${locationId}`, null);
    }

    DeletePickupLocation(supplierId: number, locationId: number): Observable<void> {
        return this.http.delete<void>(environment.apiBaseUrl + url`Admin/Suppliers/${supplierId}/pickupLocations/${locationId}`);
    }

    AddDeliveryLocation(supplierId: number, locationId: number): Observable<void> {
        return this.http.put<void>(environment.apiBaseUrl + url`Admin/Suppliers/${supplierId}/deliveryLocations/${locationId}`, null);
    }

    DeleteDeliveryLocation(supplierId: number, locationId: number): Observable<void> {
        return this.http.delete<void>(environment.apiBaseUrl + url`Admin/Suppliers/${supplierId}/deliveryLocations/${locationId}`);
    }

    AddUser(supplierId: number, userEmail: string): Observable<void> {
        return this.http.put<void>(environment.apiBaseUrl + url`Admin/Suppliers/${supplierId}/users/${userEmail}`, null);
    }

    DeleteUser(supplierId: number, userEmail: string): Observable<void> {
        return this.http.delete<void>(environment.apiBaseUrl + url`Admin/Suppliers/${supplierId}/users/${userEmail}`);
    }

    @Cacheable({ async: true })
    GetAccountName(accountId: number): Observable<string> {
        return this.http.get<Account>(environment.apiBaseUrl + url`Admin/Turvo/Accounts/${accountId}`)
            .pipe(map(account => account?.name ?? ''));
    }

    @Cacheable({ async: true })
    GetCustomerName(customerId: number): Observable<string> {
        return this.http.get<Customer>(environment.apiBaseUrl + url`Admin/Turvo/Customers/${customerId}`)
            .pipe(map(customer => customer?.name ?? ''));
    }

    @Cacheable({ async: true })
    GetLocationName(locationId: number): Observable<string> {
        return this.http.get<LocationSummary>(environment.apiBaseUrl + url`Admin/Turvo/Locations/${locationId}`)
            .pipe(map(location => location?.name ?? ''));
    }
}
