import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Item } from '../models/item';
import { environment } from '@environment';

@Injectable({
  providedIn: 'root'
})
export class ItemService {

    constructor(private http: HttpClient) { }

    getAllItems(supplierId: number | null): Observable<Item[]> {
        return this.http.get<Item[]>(environment.apiBaseUrl + `Item/${supplierId}`);
    }

    createItem(create: Item): Observable<Item> {
        return this.http.post<Item>(environment.apiBaseUrl + 'Item', create);
    }

    updateItem(update: Item): Observable<Item> {
        return this.http.put<Item>(environment.apiBaseUrl + 'Item', update);
    }
}
