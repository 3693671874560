import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';
import { LuxonModule } from 'luxon-angular';

import { MessageService } from 'primeng/api';

import { CardModule } from 'primeng/card';
import { MessageModule } from 'primeng/message';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { DropdownModule } from 'primeng/dropdown';
import { CalendarModule } from 'primeng/calendar';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { InputTextModule } from 'primeng/inputtext';
import { DialogModule } from 'primeng/dialog';
import { CheckboxModule } from 'primeng/checkbox';
import { OverlayPanelModule } from 'primeng/overlaypanel';

import { environment } from '@environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ThemeBreadcrumbComponent } from './theme/theme.breadcrumb.component';
import { ThemeComponent } from './theme/theme.component';
import { ThemeFooterComponent } from './theme/theme.footer.component';
import { ThemeMenuComponent, ThemeSubMenuComponent } from './theme/theme.menu.component';
import { ThemeTopBarComponent } from './theme/theme.topbar.component';

import { HomeComponent } from './pages/home/home.component';
import { UnauthorizedComponent } from './pages/unauthorized/unauthorized.component';
import { ApiInterceptor } from './guards/api.interceptor';
import { CurrentOrdersComponent } from './pages/current-orders/current-orders.component';
import { LoadRequestComponent } from './pages/order-form/load-request.component';
import { ItemFormComponent } from './pages/order-form/item-form/item-form.component';
import { ErrorBlockComponent } from './shared-components/error-block/error-block.component';
import { VarDirective } from './directives/var.directive';
import { NumericDirective } from './directives/numeric.directive';
import { SupplierManagementComponent } from './pages/supplier-admin/supplier-admin.component';

@NgModule({
    declarations: [
        AppComponent,
        ThemeBreadcrumbComponent,
        ThemeComponent,
        ThemeFooterComponent,
        ThemeMenuComponent,
        ThemeSubMenuComponent,
        ThemeTopBarComponent,
        HomeComponent,
        UnauthorizedComponent,
        CurrentOrdersComponent,
        LoadRequestComponent,
        ItemFormComponent,
        ErrorBlockComponent,
        VarDirective,
        NumericDirective,
        SupplierManagementComponent,
    ],
    imports: [
        AuthModule.forRoot(environment.authSettings),
        AppRoutingModule,
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        HttpClientModule,
        LuxonModule,

        CalendarModule,
        CardModule,
        CheckboxModule,
        DialogModule,
        DropdownModule,
        InputTextareaModule,
        InputTextModule,
        MessageModule,
        OverlayPanelModule,
        ReactiveFormsModule,
        ScrollPanelModule,
        TableModule,
        ToastModule
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthHttpInterceptor,
            multi: true
        }, {
            provide: HTTP_INTERCEPTORS,
            useClass: ApiInterceptor,
            multi: true
        },
        MessageService
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
