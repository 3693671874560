<div [ngClass]="{ 'layout-topbar': true, 'test': isTestEnvironment }">
    <a href="#" id="topbar-menu-button" (click)="menuButtonClick($event)">
        <i class="pi pi-angle-left arrow"></i>
        <i class="pi pi-angle-up mobile"></i>
    </a>

    <span class="logo-container">
      <img src="/assets/propak.svg" alt="Propak" />
      SupplierLink {{ isTestEnvironment ? 'Testing' : '' }}
    </span>

    <a id="topbar-profile-menu-button" href="#" (click)="logout()">
        <span>Sign Out</span>
        <i class="pi pi-sign-out"></i>
    </a>

    <a id="topbar-profile-username">
        <span [title]="username">
            {{ displayName }}
        </span>
    </a>
</div>
