import { Component } from '@angular/core';

import { AuthService } from '../services/auth.service';

import { ThemeComponent } from './theme.component';
import { environment } from '@environment';

@Component({
  selector: 'app-theme-topbar',
  templateUrl: './theme.topbar.component.html'
})
export class ThemeTopBarComponent {

    username: string = '';
    displayName: string = '';
    isTestEnvironment: boolean = false;

    constructor(private app: ThemeComponent, private authService: AuthService) {
        this.authService.getDisplayName().subscribe(name => { this.displayName = name ?? ''; });
        this.authService.getUsername().subscribe(username => { this.username = username; });
        this.isTestEnvironment = !environment.production;
    }

    menuButtonClick(event: any): void {
        this.app.onMenuButtonClick(event);
    }

    logout(): void {
        this.authService.logout();
    }
}
