<form [formGroup]="itemForm" (ngSubmit)="saveItem()">
  <div class="form">
    <p-card>
      <label>Select Item</label>
      <p-dropdown
        [options]="(itemSelect | async) ?? []"
        placeholder="-- Select Item --"
        formControlName="itemId"
        inputId="itemId"
        [filter]="true"
        appendTo="body"
        (onChange)="setItemFormInfo($event)">
      </p-dropdown>
    </p-card>
  </div>
  <div class="form-buttons" *ngIf="!isItemSelected">
    <button pButton type="button"
      class="p-button-secondary"
      icon="pi pi-times"
      label="Cancel"
      (click)="closeDialog(); $event.preventDefault();">
    </button>
  </div>
  <div *ngIf="isItemSelected">
    <p-card header="Item Info">
      <div class="form">
        <ng-container>
          <div class="form-group">
            <label class="required" for="description">Description</label>
            <input pInputText required maxlength="100" type="text" id="description" formControlName="description" />
            <error-block [field]="description" [messages]="{
              required: 'Description is required.',
              maxLength: 'Description should be less than 100 characters'
            }"></error-block>
          </div>
          <div class="form-group">
            <label class="required" for="quantity">Quantity</label>
            <input pInputText type="text" id="quantity" formControlName="quantity" appNumeric />
            <error-block [field]="quantity" [messages]="{
              required: 'Quantity is required.'
            }"></error-block>
          </div>
          <div class="form-group">
            <label class="required">Units</label>
            <p-dropdown
              [options]="unitSelect"
              placeholder="-- Select Unit --"
              formControlName="unitType"
              inputId="unitType">
            </p-dropdown>
            <error-block [field]="unitType" [messages]="{
              required: 'Units is required.'
            }"></error-block>
          </div>
          <div class="form-group">
            <label class="required" for="handlingQuantity">Handling Qty</label>
            <input pInputText type="text" id="handlingQuantity" formControlName="handlingQuantity" appNumeric />
            <error-block [field]="handlingQuantity" [messages]="{
              required: 'Handling Quantity is required.'
            }"></error-block>
          </div>
          <div class="form-group">
            <label class="required">Handling Units</label>
            <p-dropdown
              [options]="handlingUnitSelect"
              placeholder="-- Select Handling --"
              formControlName="handlingUnitType"
              inputId="handlingUnitType">
            </p-dropdown>
            <error-block [field]="handlingUnitType" [messages]="{
              required: 'Handling Units is required.'
            }"></error-block>
          </div>
        </ng-container>
      </div>
    </p-card>
    <p-card header="Item Metrics">
      <div class="form">
        <div class="form-group">
          <label class="required" for="length">Length</label>
          <input pInputText type="text" id="length" formControlName="length" appNumeric />
          <error-block [field]="length" [messages]="{
            required: 'Length is required.'
          }"></error-block>
        </div>
        <div class="form-group">
          <label class="required" for="width">Width</label>
          <input pInputText type="text" id="width" formControlName="width" appNumeric required />
          <error-block [field]="width" [messages]="{
            required: 'Width is required.'
          }"></error-block>
        </div>
        <div class="form-group">
          <label class="required" for="height">Height</label>
          <input pInputText type="text" id="height" formControlName="height" appNumeric />
          <error-block [field]="height" [messages]="{
            required: 'Height is required.'
          }"></error-block>
        </div>
        <div class="form-group">
          <label class="required">Dimension Units</label>
          <p-dropdown
            [options]="dimensionUnitSelect"
            placeholder="-- Select Unit --"
            formControlName="dimensionUnitType"
            inputId="dimensionUnitType">
          </p-dropdown>
          <error-block [field]="dimensionUnitType" [messages]="{
            required: 'Dimension Units is required.'
          }"></error-block>
        </div>
        <div class="form-group">
          <label class="required" for="totalGrossWeight">Total Gross Weight</label>
          <input pInputText type="text" id="totalGrossWeight" formControlName="totalGrossWeight" appNumeric />
          <error-block [field]="totalGrossWeight" [messages]="{
            required: 'Total Gross Weight is required.'
          }"></error-block>
        </div>
        <div class="form-group">
          <label class="required">Weight Unit</label>
          <p-dropdown
            [options]="weightUnitSelect"
            placeholder="-- Select Weight Unit --"
            formControlName="weightUnitType"
            inputId="weightUnitType">
          </p-dropdown>
          <error-block [field]="weightUnitType" [messages]="{
            required: 'Weight Units is required.'
          }"></error-block>
        </div>
      </div>
      <div class="form">
        <div class="form-group">
          <label for="nmfcNumber">NMFC #</label>
          <input pInputText type="text" id="nmfcNumber" formControlName="nmfcNumber" minlength="4" maxlength="6" appNumeric />
          <error-block [field]="nmfcNumber" [messages]="{
            minlength: 'NMFC # must be at least 4 digits.',
            maxlength: 'NMFC # must be no more than 6a digits.',
            pattern: 'NMFC # must be numeric.'
          }"></error-block>
      </div>
        <div class="form-group">
          <label for="nmfcSub">NMFC sub</label>
          <input pInputText type="text" id="nmfcSub" formControlName="nmfcSub" maxlength="2" appNumeric />
          <error-block [field]="nmfcSub" [messages]="{
            maxLength: 'NMFC sub must be no more than 2 digits.',
            pattern: 'NMFC sub must be numeric.'
          }"></error-block>
        </div>
        <div class="form-group">
          <label>Freight Class</label>
          <p-dropdown
            [options]="freightClassSelect"
            placeholder="-- Select Handling --"
            formControlName="freightClassType"
            inputId="freightClassType">
          </p-dropdown>
        </div>
    </div>
    </p-card>
    <p-card header="Temperature Control">
      <div class="form">
        <div class="form-group">
          <label>Temp Controlled?</label>
          <p-checkbox
            [binary]="true"
            formControlName="isTempControlled"
            inputId="isTempControlled">
          </p-checkbox>
        </div>
        <ng-container *ngIf="isTempControlledObs | async">
          <div class="form-group">
            <label for="minTemp" class="required">Min Temp</label>
            <input pInputText type="text" id="minTemp" formControlName="minTemp" appNumeric />
            <error-block [field]="minTemp" [messages]="{
              required: 'Max Temp is required.'
            }"></error-block>
          </div>
          <div class="form-group">
            <label for="maxTemp" class="required">Max Temp</label>
            <input pInputText type="text" id="maxTemp" formControlName="maxTemp" appNumeric />
            <error-block [field]="maxTemp" [messages]="{
              required: 'Max Temp is required.',
              tempOrder: 'Max Temp should be greater than min temp.'
            }"></error-block>
          </div>
          <div class="form-group">
            <label [for]="temperatureUnitType" class="required">Temperature Units</label>
            <p-dropdown
              [options]="tempUnitSelect"
              placeholder="-- Select Unit --"
              formControlName="temperatureUnitType"
              inputId="temperatureUnitType">
            </p-dropdown>
          </div>
        </ng-container>
      </div>
    </p-card>
    <p-card header="Hazmat">
      <div class="form">
        <div class="form-group">
          <label>Hazmat?</label>
          <p-checkbox
            [binary]="true"
            formControlName="isHazmat"
            inputId="isHazmat">
          </p-checkbox>
        </div>
        <ng-container *ngIf="isHazmatObs | async">
          <div class="form-group">
            <label class="required" for="identificationNumber">UN Number</label>
            <input pInputText type="text" id="identificationNumber" formControlName="identificationNumber" maxlength="4" appNumeric />
            <error-block [field]="identificationNumber" [messages]="{
              required: 'UN # is required.',
              maxLength: 'UN # must be 4 characters',
              pattern: 'UN # must be numeric'
            }"></error-block>
          </div>
          <div class="form-group">
            <label class="required" for="properShippingName">Proper Shipping Name</label>
            <input pInputText type="text" id="properShippingName" formControlName="properShippingName" />
            <error-block [field]="properShippingName" [messages]="{
              required: 'Proper Shipping Name is required.'
            }"></error-block>
          </div>
          <div class="form-group">
            <label class="required" for="hazardClass">Hazard Class</label>
            <input pInputText type="text" id="hazardClass" formControlName="hazardClass" maxlength="3" appNumeric [decimals]="1" />
            <error-block [field]="hazardClass" [messages]="{
              required: 'Hazard Class is required.',
              pattern: 'Hazard Class must have the formats # or #.#'
            }"></error-block>
          </div>
          <div class="form-group">
            <label class="required" for="packingGroup">Packing Group</label>
            <p-dropdown
              [options]="packingGroupSelect"
              placeholder="-- Select Handling --"
              formControlName="packingGroup"
              inputId="packingGroup"
              appendTo="body">
            </p-dropdown>
            <error-block [field]="packingGroup" [messages]="{
              required: 'Packing Group is required.'
            }"></error-block>
          </div>
          <div class="form-group">
            <label class="required" for="emergencyContact">Emergency Contact</label>
            <input pInputText type="text" id="emergencyContact" formControlName="emergencyContact" />
            <error-block [field]="emergencyContact" [messages]="{
              required: 'Emergency Contact is required.'
            }"></error-block>
          </div>
        </ng-container>
      </div>
    </p-card>
    <div class="form-buttons">
      <button pButton
        class="p-button-secondary"
        icon="pi pi-times"
        label="Cancel"
        (click)="closeDialog(); $event.preventDefault();">
      </button>
      <button pButton
        type="submit"
        icon="pi pi-save"
        [ngClass]="{ 'p-state-disabled': !itemForm.valid}"
        label="Save">
      </button>
    </div>
  </div>
</form>

