import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { filterNone } from '../helpers/custom-operators';
import { Lookup } from '../models/lookup';

@Injectable({
    providedIn: 'root'
})
export class LookupService {

    private _lookups: BehaviorSubject<Lookup[] | null> = new BehaviorSubject<Lookup[] | null>(null);

    get Lookups(): Observable<Lookup[]> {
        return this._lookups.pipe(filterNone());
    }

    constructor(private http: HttpClient) {
        this.http.get<Lookup[]>(environment.apiBaseUrl + 'Lookups').subscribe(context => {
            this._lookups.next(context);
        });
    }
}
