<ng-template ngFor let-child let-i="index" [ngForOf]="items">
    <li [class]="child.badgeStyleClass ?? ''" *ngIf="child.visible === false ? false : true">
        <a [href]="child.url||'#'" (click)="itemClick($event,child,i)" (mouseenter)="onMouseEnter(i)"
           *ngIf="!child.routerLink" [ngClass]="child.styleClass"
           [attr.tabindex]="!visible ? '-1' : null" [attr.target]="child.target">
            <i *ngIf="child.icon" [ngClass]="child.icon"></i>
            <span>{{child.label}}</span>
            <i class="pi pi-angle-down layout-menuitem-toggler" *ngIf="child.items"></i>
            <span class="menuitem-badge" *ngIf="child.badge">{{child.badge}}</span>
        </a>

        <a (click)="itemClick($event,child,i)" (mouseenter)="onMouseEnter(i)" *ngIf="child.routerLink"
           [routerLink]="child.routerLink" routerLinkActive="active-menuitem-routerlink" [fragment]="child.fragment"
           [routerLinkActiveOptions]="{exact: true}" [attr.tabindex]="!visible ? '-1' : null" [attr.target]="child.target">
            <i *ngIf="child.icon" [ngClass]="child.icon"></i>
            <span>{{child.label}}</span>
            <i class="pi pi-angle-down layout-menuitem-toggler" *ngIf="child.items"></i>
            <span class="menuitem-badge" *ngIf="child.badge">{{child.badge}}</span>
        </a>
        <div class="layout-menu-tooltip">
            <div class="layout-menu-tooltip-arrow"></div>
            <div class="layout-menu-tooltip-text">{{child.label}}</div>
        </div>
        <ul app-theme-submenu [item]="child" *ngIf="child.items" [visible]="isActive(child)" [reset]="reset" [parentActive]="isActive(child)"
            [@children]="root ? isActive(child) ?
            'visible' : 'hidden' : isActive(child) ? 'visibleAnimated' : 'hiddenAnimated'"></ul>
    </li>
</ng-template>
