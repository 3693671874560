import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, Validators } from '@angular/forms';
import { DateTime } from 'luxon';
import { MessageService, SelectItem } from 'primeng/api';
import { Item } from '../../models/item';
import { SupplierContext } from '../../models/supplier';
import { ContextService } from '../../services/context.service';
import { combineLatest, Observable, Subscription, zip } from 'rxjs';
import { ItemService } from '../../services/item.service';
import { LoadRequestService } from '../../services/load-request.service';
import { LoadRequest } from '../../models/load-request';
import { LookupService } from '../../services/lookup.service';
import { map, tap } from 'rxjs/operators';
import { ActivityService } from 'src/app/services/activity.service';
import { streamControlValue } from 'src/app/helpers/custom-operators';
import { LocationDetail } from 'src/app/models/location';
import { DateDetail } from 'src/app/models/order-info';

@Component({
  selector: 'app-order-form',
  templateUrl: './load-request.component.html',
  styleUrls: ['./load-request.component.scss']
})
export class LoadRequestComponent implements OnInit, OnDestroy {
    loadRequestForm = this.fb.group({
        customerId: new FormControl<number | null>(null, Validators.required),
        supplierId: new FormControl<number | null>(null, Validators.required),
        accountId: new FormControl<number | null>(null, Validators.required),
        shipFromSiteId: new FormControl<number | null>(null, Validators.required),
        shipToSiteId: new FormControl<number | null>(null, Validators.required),
        purchaseOrders: this.fb.array([new FormControl(null)], [Validators.maxLength(20), Validators.required]),
        pickupStartDate: new FormControl(null, Validators.required),
        pickupStartTime: new FormControl(this.nearestInterval(0)),
        pickupEndDate: new FormControl(null, Validators.required),
        pickupEndTime: new FormControl(this.nearestInterval(0)),
        pickupNote: new FormControl(''),
        deliveryStartDate: new FormControl(null, Validators.required),
        deliveryStartTime: new FormControl(this.nearestInterval(1)),
        deliveryEndDate: new FormControl(null, Validators.required),
        deliveryEndTime: new FormControl(this.nearestInterval(1)),
        deliveryNote: new FormControl(''),
    });

    customerOptions: SelectItem[] = [];
    supplierOptions: SelectItem[] = [];
    accountOptions: SelectItem[] = [];
    shipFroms: SelectItem[] = [];
    shipTos: SelectItem[] = [];
    orderItems: Item[] = [];
    supplierItems: Item[] = [];

    showItemDialog: boolean = false;
    private subscriptions: Subscription[] = [];
    context: SupplierContext[]  = [];

    selectedShipFrom: LocationDetail | null = null;
    selectedShipTo: LocationDetail | null = null;

    get supplierId(): FormControl { return <FormControl> this.loadRequestForm.get('supplierId'); }
    get accountId(): FormControl { return <FormControl> this.loadRequestForm.get('accountId'); }
    get customerId(): FormControl { return <FormControl> this.loadRequestForm.get('customerId'); }
    get shipFromSiteId(): FormControl { return <FormControl> this.loadRequestForm.get('shipFromSiteId');}
    get shipToSiteId(): FormControl { return <FormControl> this.loadRequestForm.get('shipToSiteId');}
    get purchaseOrders(): FormArray {
        return this.loadRequestForm.controls['purchaseOrders'] as FormArray;
    }
    get pickupStartDate(): FormControl { return <FormControl> this.loadRequestForm.get('pickupStartDate');}
    get pickupStartTime(): FormControl { return <FormControl> this.loadRequestForm.get('pickupStartTime');}
    get pickupEndDate(): FormControl { return <FormControl> this.loadRequestForm.get('pickupEndDate');}
    get pickupEndTime(): FormControl { return <FormControl> this.loadRequestForm.get('pickupEndTime');}
    get pickupNote(): FormControl { return <FormControl> this.loadRequestForm.get('pickupNote');}
    get deliveryStartDate(): FormControl { return <FormControl> this.loadRequestForm.get('deliveryStartDate');}
    get deliveryStartTime(): FormControl { return <FormControl> this.loadRequestForm.get('deliveryStartTime');}
    get deliveryEndDate(): FormControl { return <FormControl> this.loadRequestForm.get('deliveryEndDate');}
    get deliveryEndTime(): FormControl { return <FormControl> this.loadRequestForm.get('deliveryEndTime');}
    get deliveryNote(): FormControl { return <FormControl> this.loadRequestForm.get('deliveryNote');}

    addPurchaseOrderNumber(): void {
        const newControl = new FormControl('');
        this.purchaseOrders.push(newControl);
    }

    constructor(private fb: FormBuilder,
                private supplierContext: ContextService,
                private itemService: ItemService,
                private loadRequestService: LoadRequestService,
                private messageService: MessageService,
                private lookupService: LookupService,
                private activityService: ActivityService) {
        this.loadRequestForm.addValidators(formVals => {
            const dates = this.getAllDateDetails();

            const errors: string[] = [];

            if (dates.pickupStart.date > dates.pickupEnd.date)
                errors.push('pickupWindowSequence');

            if (dates.deliveryStart.date > dates.deliveryEnd.date)
                errors.push('deliveryWindowSequence');

            if (dates.pickupStart.date > dates.deliveryStart.date)
                errors.push('startWindowSequence');

            if (dates.pickupEnd.date > dates.deliveryEnd.date)
                errors.push('endWindowSequence');

            return errors.length
                ? Object.fromEntries(errors.map(e => [e, true]))
                : null;
        });
    }

    ngOnInit(): void {
        const contextObs = this.supplierContext.SupplierContext.pipe(tap(context => {
            this.context = context;

            this.supplierOptions = context.map(c => ({ value: c.id, label: c.name }));
            if (this.supplierOptions.length === 1)
                this.supplierId.setValue(this.supplierOptions[0].value, { emitEvent: true });
        }));

        const supplierObs = combineLatest([
            //concat(of(this.supplierId.value), this.supplierId.valueChanges),
            streamControlValue(this.supplierId),
            this.supplierContext.SupplierContext
        ]).pipe(tap(([sId, context]) => {
            if (sId && context) {
                const cxt = context.find(c => c.id === sId);

                this.shipFroms = cxt?.shippingLocations.map(sl => ({
                    value: sl.id,
                    label: sl.name
                })) ?? [];
                this.shipTos = cxt?.deliveryLocations.map(sl => ({
                    value: sl.id,
                    label: sl.name
                })) ?? [];
                this.accountOptions = cxt?.accounts.map(a => ({
                    value: a.id,
                    label: a.name
                })) ?? [];
                this.customerOptions = cxt?.customers.map(co => ({
                    value: co.id,
                    label: co.name
                })) ?? [];

                if (this.accountOptions.length === 1)
                    this.accountId.setValue(this.accountOptions[0].value);
                if (this.customerOptions.length === 1)
                    this.customerId.setValue(this.customerOptions[0].value);

                this.itemService.getAllItems(sId).subscribe(items =>{
                    this.supplierItems = items;
                });
            }
        }));

        this.subscriptions = [
            combineLatest([
                streamControlValue(this.shipFromSiteId),
                this.supplierContext.SupplierContext
            ]).subscribe(([id, context]) => {
                const cxt = context.find(c => c.id === this.supplierId.value);
                this.selectedShipFrom = cxt?.shippingLocations.find(l => l.id === id) ?? null;
            }),

            combineLatest([
                streamControlValue(this.shipToSiteId),
                this.supplierContext.SupplierContext
            ]).subscribe(([id, context]) => {
                const cxt = context.find(c => c.id === this.supplierId.value);
                this.selectedShipTo = cxt?.deliveryLocations.find(l => l.id === id) ?? null;
            }),

            zip(contextObs, supplierObs)
                .pipe(this.activityService.Wait())
                .subscribe()
        ];
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    removePurchaseOrderNumber(index: number): void {
        this.purchaseOrders.removeAt(index);
    }

    removeItem(item: Item): void {
        this.orderItems = this.orderItems.filter(i => i !== item);
    }

    nearestInterval(day: number): Date {
        const today = DateTime.local().plus({ days: day }).startOf('minute');

        return today.set({ minute: Math.round(today.minute / 15) * 15 % 60 }).toJSDate();
    }

    addItemDialog(): void {
        this.showItemDialog = true;

    }

    itemAdded(item: Item): void {
        if (item) {
            this.orderItems = [...this.orderItems, item];
        }
        this.showItemDialog = false;
    }

    submitOrder(): void {
        if (!this.loadRequestForm.valid) {
            this.loadRequestForm.markAllAsTouched();
            this.messageService.add({ severity: 'warn', summary: 'Could not Submit Order', detail: 'Please correct the listed errors.' });
            return;
        }

        const order = this.setModelData();
        this.loadRequestService.createOrder(order).subscribe(_ => {
            this.messageService.add({
                severity: 'success',
                summary: 'Load Request Received',
                detail: 'The order request has been received. You will receive a confirmation email once it is processed.',
                sticky: true
            });

            this.loadRequestForm.reset();
            this.pickupStartTime.setValue(this.nearestInterval(0));
            this.pickupEndTime.setValue(this.nearestInterval(0));
            this.deliveryStartTime.setValue(this.nearestInterval(1));
            this.deliveryEndTime.setValue(this.nearestInterval(1));
            this.orderItems = [];
        });
    }

    private setModelData(): LoadRequest {
        return {
            customerId: this.customerId.value,
            supplierId: this.supplierId.value,
            accountId: this.accountId.value,
            shipFromSiteId: this.shipFromSiteId.value,
            shipToSiteId: this.shipToSiteId.value,
            pickupNote: this.pickupNote.value,
            deliveryNote: this.deliveryNote.value,
            purchaseOrderNumbers: this.purchaseOrders.value,
            items: this.orderItems,
            ...this.getAllDateDetails()
        };
    }

    private getAllDateDetails(): { pickupStart: DateDetail, pickupEnd: DateDetail, deliveryStart: DateDetail, deliveryEnd: DateDetail } {
        const pickupTimeZone = this.context
            .find(c => c.id === this.supplierId.value)
            ?.shippingLocations
            .find(sl => sl.id === this.shipFromSiteId.value)
            ?.timezone ?? DateTime.local().zoneName;
        const deliveryTimeZone = this.context
            .find(c => c.id === this.supplierId.value)
            ?.deliveryLocations
            .find(dl => dl.id === this.shipToSiteId.value)
            ?.timezone ?? DateTime.local().zoneName;

        return {
            pickupStart: this.createDateDetail(
                DateTime.fromJSDate(this.pickupStartDate.value),
                DateTime.fromJSDate(this.pickupStartTime.value),
                pickupTimeZone),
            pickupEnd: this.createDateDetail(
                DateTime.fromJSDate(this.pickupEndDate.value),
                DateTime.fromJSDate(this.pickupEndTime.value),
                pickupTimeZone),
            deliveryStart: this.createDateDetail(
                DateTime.fromJSDate(this.deliveryStartDate.value),
                DateTime.fromJSDate(this.deliveryStartTime.value),
                deliveryTimeZone),
            deliveryEnd: this.createDateDetail(
                DateTime.fromJSDate(this.deliveryEndDate.value),
                DateTime.fromJSDate(this.deliveryEndTime.value),
                deliveryTimeZone)
        };
    }

    private createDateDetail(date: DateTime, time: DateTime, timezone: string): DateDetail {
        let datetime = DateTime.local().startOf('minute').setZone(timezone);

        if (date.isValid)
            datetime = datetime.set({ year: date.year, month: date.month, day: date.day });

        if (time.isValid)
            datetime = datetime.set({ hour: time.hour, minute: time.minute, second: 0 });

        return {
            date: datetime.toUTC(),
            hasTime: true,
            timezone
        };
    }

    lookupHandlingUnit(handlingUnitType: string | null): Observable<string> {
        return this.lookupService.Lookups.pipe(map(l => l
            .find(x => x.id === 'Unit')
            ?.values
            .find(v => v.key === handlingUnitType)
            ?.value ?? ''));
    }

    lookupDimUnit(dimUnitType: string | null): Observable<string> {
        return this.lookupService.Lookups.pipe(map(l => l
            .find(x => x.id === 'LengthUnits')
            ?.values
            .find(v => v.key === dimUnitType)
            ?.value ?? ''));
    }
}
