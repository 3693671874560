import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, filter, take } from 'rxjs/operators';

import { AuthService as Auth0Service, User } from '@auth0/auth0-angular';

import { ActivityService } from './activity.service';
import { filterNone } from '../helpers/custom-operators';
import { environment } from '@environment';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private roles: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);

    private authMessage = new BehaviorSubject<{ summary: string, detail: string} | null>(null);

    private errState = false;

    get Roles(): Observable<string[]> {
        return this.roles.asObservable().pipe(filter(roles => !!roles));
    }

    get AuthMessage(): Observable<{ summary: string, detail: string} | null> {
        return this.authMessage.asObservable();
    }

    constructor(
            private auth: Auth0Service,
            private activityService: ActivityService) {
        this.auth.isAuthenticated$
            .pipe(take(1))
            .subscribe(isAuthenticated => {
                if (!isAuthenticated)
                    this.forceLogin();
                else {
                }
            });

        this.auth.idTokenClaims$
            .pipe(this.activityService.Wait('user roles'))
            .subscribe(claims => {
                this.roles.next(claims?.['https://supplierlink.propak.com/roles'] ?? []);
            });
    }

    forceLogin(): void {
        this.auth.loginWithRedirect();
    }

    logout(): void {
        this.auth.logout({
            logoutParams: {
                returnTo: environment.authSettings.authorizationParams?.redirect_uri
            }
        });
    }

    private getAccount(): Observable<User> {
        return this.auth.user$.pipe(filterNone());
    }

    getUsername(): Observable<string> {
        return this.getAccount().pipe(
            map(u => u.email),
            filterNone());
    }

    getDisplayName(): Observable<string> {
        return this.getAccount().pipe(
            map(u => u.name),
            filterNone());
    }

    getUserRoles(): string[] {
        return this.roles.value;
    }

    isUserInAnyRoles(roles: string[]): Observable<boolean> {
        if (!roles || roles.length === 0)
            return of(true);

        return this.roles.pipe(
            filter(userRoles => userRoles && userRoles.length > 0),
            take(1),
            map(userRoles => roles.some(r => userRoles.some(ur => ur === r)))
        );
    }

    isAuthenticated(): Observable<boolean> {
        return this.auth.isAuthenticated$;
    }
}
