export function url(strings: TemplateStringsArray, ...variables: any[]): string {
    let u = '';

    for (let i = 0; i < variables.length; i++)
        u += strings[i] + encodeURIComponent(variables[i]);

    u += strings[strings.length - 1];

    return u;
}
