import { Directive, ElementRef, HostListener, Input } from '@angular/core';

// taken from https://gist.github.com/ahmeti/5ca97ec41f6a48ef699ee6606560d1f7

@Directive({
    selector: '[appNumeric]'
})
export class NumericDirective {
    @Input() decimals: number = 0;

    private oldValue: string = '';

    constructor(private el: ElementRef) {}

    private check(value: string): boolean {
        if (this.decimals <= 0) {
            return !!String(value).match(new RegExp(/^((-?)|(-?\d+))$/));
        } else {
            const regExpString = `^((-?\\.?)|(-?\\d+\\.?)|(-?\\d*\\.\\d{1,${this.decimals}}))$`;
            return !!String(value).match(new RegExp(regExpString));
        }
    }

    private run(currentValue: string): void {
        if (currentValue !== '' && !this.check(currentValue)) {
            this.el.nativeElement.value = this.oldValue;
            this.el.nativeElement.dispatchEvent(new InputEvent('input', {
                view: window,
                bubbles: true,
                cancelable: true,
                data: '',
                inputType: 'deleteContentBackward'
            }));
        }
        else
            this.oldValue = currentValue;
    }

    @HostListener('blur', ['$event'])
    onBlur(_event: any): void {
        if (this.el.nativeElement.value.match(/^-?\.?$/))
            this.el.nativeElement.value = this.oldValue;
    }

    @HostListener('input', ['$event'])
    onInput(_event: InputEvent): void {
        this.run(this.el.nativeElement.value);
    }

    @HostListener('focus', ['$event'])
    onFocus(_event: FocusEvent): void {
        this.oldValue = this.el.nativeElement.value;
    }
}
