import { Component, Input } from '@angular/core';

import { DateTime } from 'luxon';

@Component({
  selector: 'app-theme-footer',
  templateUrl: './theme.footer.component.html'
})
export class ThemeFooterComponent {
    @Input() buildNumber: string = '';

    loadTime: string = DateTime.local().toISO();


    year: number = (new Date()).getFullYear();
}
