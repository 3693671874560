import { Component, OnDestroy, OnInit } from '@angular/core';
import { environment } from '@environment';
import { Subscription } from 'rxjs';
import { Lookup } from 'src/app/models/lookup';
import { SupplierContext } from 'src/app/models/supplier';
import { AuthService } from 'src/app/services/auth.service';
import { ContextService } from 'src/app/services/context.service';
import { LookupService } from 'src/app/services/lookup.service';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {
    response: SupplierContext[] | null = null;
    lookups: Lookup[] | null = null;
    canSeeDebug: boolean = false;

    private subscriptions: Subscription[] = [];

    constructor(private authService: AuthService, private contextService: ContextService, private lookupService: LookupService) {
    }

    ngOnInit(): void {
        this.authService.isUserInAnyRoles([ 'developers' ]).subscribe(isInRoles => {
            if (isInRoles && !environment.production) {
                this.canSeeDebug = true;
                this.subscriptions = [
                    this.contextService.SupplierContext.subscribe(context => {
                        this.response = context;
                    }),
                    this.lookupService.Lookups.subscribe(l => {
                        this.lookups = l;
                    })
                ];
            }
        });
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(s => s.unsubscribe());
    }
}
