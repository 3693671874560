import { Injectable } from '@angular/core';
import { environment } from '@environment';

import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { once } from '../helpers/custom-operators';

@Injectable({
    providedIn: 'root'
})
export class ActivityService {
    private _handles: BehaviorSubject<{ handle: symbol, label: string | null }[]>;
    private _isBusy: Observable<boolean>;

    public get IsBusy(): Observable<boolean> {
        return this._isBusy;
    }

    constructor() {
        this._handles = new BehaviorSubject<{ handle: symbol, label: string | null }[]>([]);
        this._isBusy = this._handles.pipe(map(handles => handles.length > 0));
    }

    StartWorking(label: string | null = null): symbol {
        if (!environment.production && label)
            console.log(`Activity Service: ${label} started working`);

        const handle = Symbol();
        this._handles.next([{ handle, label }, ...this._handles.value]);
        return handle;
    }

    FinishWorking(handle: symbol): void {
        if (!environment.production) {
            const match = this._handles.value.find(h => h.handle === handle);

            if (match && match.label)
                console.log(`Activity Service: ${match.label} finished working`);
        }

        this._handles.next(this._handles.value.filter(h => h.handle !== handle));
    }

    WaitFor<T>(observable: Observable<T>, label: string = ''): Observable<T> {
        return this.Wait<T>(label)(observable);
    }

    Wait<T>(label: string = ''): (src: Observable<T>) => Observable<T> {
        return (observable: Observable<T>): Observable<T> => {
            const handle = this.StartWorking(label);

            return observable.pipe(this.Finish<T>(handle));
        };
    }

    Finish<T>(handle: symbol): (src: Observable<T>) => Observable<T> {
        return (observable: Observable<T>) => observable.pipe(
            once((_: T) => {
                this.FinishWorking(handle);
            }),
            catchError((err: unknown) => {
                this.FinishWorking(handle);
                return throwError(() => err);
            }));
    }
}
